import React, { useEffect, useState } from "react";
import classes from "./AuctionProducts.module.scss";
import SideNav from "../UI/navbars/SideNav";
import TopNav from "../UI/navbars/TopNav";
import { get_request } from "../utils/ApiHandlers";
import { useNavigate } from "react-router-dom";
import RequestCard from "../components/RequestCard";

const Requests = () => {
    const navigate = useNavigate();
    const [requests, setRequests] = useState([]);

    useEffect(() => {
        fetchRequests();
    }, []);

    const fetchRequests = async () => {
        const response = await get_request(navigate, `/api/v1/admin/products/requests`);
        if (response.data) {
            setRequests(response.data); // Set all requests at once
        }
    };

    return (
        <>
            <TopNav />
            <div id="layoutSidenav">
                <SideNav />
                <div
                    id="layoutSidenav_content"
                    style={{
                        padding: 20,
                        maxWidth: "90%",
                        margin: "auto",
                        height: 400,
                        overflowY: "scroll",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                    }}
                >
                    {requests.length > 0 ? (
                        <div className={classes.auction_product_container}>
                            {requests.map((request) => (
                                <RequestCard
                                    key={request.id}
                                    coverImage={request.coverImage}
                                    id={request.id}
                                    artistName={request.artistName}
                                    name={request.name}
                                    price={request.price}
                                    buyerName={request.requester.name}
                                    buyerSurname={request.requester.surname}
                                    buyerEmail={request.requester.email}
                                    buyerPhone={request.requester.phone}
                                    buyerAddress={request.requester.address}
                                />
                            ))}
                        </div>
                    ) : (
                        <p>No requests available.</p>
                    )}
                </div>
            </div>
        </>
    );
};

export default Requests;
