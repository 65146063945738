import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import  classes  from "./Dashboard.module.scss";
import TopNav from "../UI/navbars/TopNav";
import SideNav from "../UI/navbars/SideNav";
import {get_request} from "../utils/ApiHandlers"; // Create a CSS module or use inline styles

const Dashboard = () => {
  const navigate = useNavigate();

  const [productRequests, setProductRequests] = useState([]);

  useEffect(() => {
    const fetchProductRequests = async () => {
      const response = await get_request(navigate, `/api/v1/admin/products/requests`);
      setProductRequests(response.data);
    };
    fetchProductRequests();
  }, []);

  return (
      <>
        <TopNav />
        <div id="layoutSidenav">
          <SideNav />
          <div id="layoutSidenav_content">
            <div className={classes.container}>
              <div className={classes.buttonGrid}>
                <button
                    className={classes.gridButton}
                    onClick={() => navigate("/AuctionList")}
                >
                  müzayedeler
                </button>
                <button
                    className={classes.gridButton}
                    onClick={() => navigate("/CreateAuction")}
                >
                  müzayede oluştur
                </button>
                <button
                    className={classes.gridButton}
                    onClick={() => navigate("/ArtistList")}
                >
                  sanatçılar
                </button>
                <button
                    className={classes.gridButton}
                    onClick={() => navigate("/CreateArtist")}
                >
                  sanatçı oluştur
                </button>
                <button
                    className={classes.gridButton}
                    onClick={() => navigate("/ProductList")}
                    >
                    ürünler
                </button>
                <button
                    className={classes.gridButton}
                    onClick={() => navigate("/ProductRequests")}
                    >
                  siparişler<br></br> <b>{productRequests.length} yeni sipariş</b>
                </button>
                <button
                    className={classes.gridButton}
                    onClick={() => navigate("/CreateProduct")}
                    >
                    ürün oluştur
                </button>
                <button
                    className={classes.gridButton}
                    onClick={() => navigate("/BlogList")}
                    >
                    bloglar
                </button>
                <button
                    className={classes.gridButton}
                    onClick={() => navigate("/CreateBlog")}
                    >
                    blog oluştur
                </button>
              </div>
            </div>
            <footer className="py-4 bg-light mt-auto">
              <div className="container-fluid px-4">
                <div className="d-flex align-items-center justify-content-between small">
                </div>
              </div>
            </footer>
          </div>
        </div>
      </>
  );
};

export default Dashboard;
