import React from "react";
import classes from "./BlogCard.module.scss";
import {useNavigate} from "react-router-dom";

const BlogCard = (props) => {
    const navigate = useNavigate();

    return (
        <div className={classes.blogItem}>
            <div onClick={() => navigate(`/UpdateBlog/${props.id}`)} className={classes.blogLink}>
                <div className={classes.blogImage}>
                    <img src={props.coverImage.url} alt={props.coverImage.alt} />
                    <div className={classes.blogTitle}>{props.name}</div>
                </div>
                <div className={classes.blogMeta}>
                    <p className={classes.blogDateAuthor}>{props.date} - upArt</p>
                    <div className={classes.redLine}></div>
                </div>
            </div>
        </div>
    );
};

export default BlogCard;
