import React, { useEffect, useRef, useState } from "react";
import SideNav from "../UI/navbars/SideNav";
import TopNav from "../UI/navbars/TopNav";
import FileUploader from "../components/FileUploader";
import {
  delete_request,
  get_request,
  post_request,
  put_request,
} from "../utils/ApiHandlers";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../components/Modal";
import classes from "./CreateArtist.module.scss";
import MarkdownEditor from "../components/MarkdownEditor";

const UpdateArtist = () => {
  const navigate = useNavigate();
  const { artistId } = useParams();
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [files, setFiles] = useState(null);
  const [descriptionVideos, setDescriptionVideos] = useState([
    { header: "", url: "" },
  ]);
  const [showModal, setShowModal] = useState(false);

  const fullNameRef = useRef();
  const [summary, setSummary] = useState("");
  const [biography, setBiography] = useState("");

  const [isSaving, setIsSaving] = useState(false);


  useEffect(() => {
    const fetchArtistDetails = async () => {
      const response = await get_request(
        navigate,
        `/api/v1/admin/artists/${artistId}`
      );
      const artist = response.data;

      fullNameRef.current.value = artist.fullName;
      setSummary(artist.summary);
      setBiography(artist.biography);
      artist.videoUrls.push({ header: "", url: "" });
      setDescriptionVideos(
        artist.videoUrls.length > 0
          ? artist.videoUrls
          : [{ header: "", url: "" }]
      );
      setFiles(
        artist?.workAssets?.length > 0 ? [...artist.workAssets, null] : [null]
      );
      setProfileImageFile(artist.profileImage);
      console.log(files);
    };
    fetchArtistDetails();
  }, [artistId]);

  const handleProfileFileChange = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const response = await put_request(
      navigate,
      `/api/v1/admin/assets/${profileImageFile?.id}`,
      formData,
      {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      }
    );
    console.log(response.data);
    setProfileImageFile(response.data);
  };

  const handleProfileFileRemove = async (file) => {
    const response = await delete_request(
      navigate,
      `/api/v1/admin/assets/${profileImageFile?.id}`
    );
    if (response.status === 200) {
      setProfileImageFile(null);
    }
  };

  const handleFileChange = async (file, index) => {
    let response;
    if (files[index]?.id) {
      const formData = new FormData();
      formData.append("file", file);
      response = await put_request(
        navigate,
        `/api/v1/admin/assets/${files[index].id}`,
        formData,
        {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        }
      );
      const newFiles = [...files];
      newFiles[index] = response.data;

      if (index === files.length - 1) {
        newFiles.push(null);
      }

      setFiles(newFiles);
    } else {
      const formData = new FormData();
      formData.append("file", file);
      response = await post_request(
        navigate,
        `/api/v1/admin/assets/${artistId}`,
        formData,
        {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        }
      );
      const newFiles = [...files];
      newFiles[index] = response.data;

      if (index === files.length - 1) {
        newFiles.push(null);
      }

      setFiles(newFiles);
    }
  };

  const handleFileRemove = async (index, imageId) => {
    console.log(index, imageId);
    const response = await delete_request(
      navigate,
      `/api/v1/admin/assets/${imageId}`
    );
    if (response.status === 200) {
      const newFiles = files.filter((_, i) => i !== index);
      if (newFiles.length === 0) {
        newFiles.push(null);
      }

      setFiles(newFiles);
    }
  };

  const handleVideoChange = (index, field, value) => {
    const newVideos = [...descriptionVideos];
    newVideos[index][field] = value;

    if (
      newVideos[index].header &&
      newVideos[index].url &&
      index === descriptionVideos.length - 1
    ) {
      newVideos.push({ header: "", url: "" });
    }

    setDescriptionVideos(newVideos);
  };

  const handleRemoveVideo = (index) => {
    const newVideos = descriptionVideos.filter((_, i) => i !== index);
    setDescriptionVideos(
      newVideos.length === 0 ? [{ header: "", url: "" }] : newVideos
    );
  };

  const onSaveHandler = async (event) => {
    setIsSaving(true);
    event.preventDefault();

    const artistUpdateRequest = {
      fullName: fullNameRef.current.value,
      summary: summary,
      biography: biography,
      descriptionVideos: descriptionVideos.filter(
        (video) => video.header && video.url
      ),
    };

    await put_request(
      navigate,
      `/api/v1/admin/artists/${artistId}`,
      artistUpdateRequest,
      {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      }
    );
    setIsSaving(false)
  };

  const onDeleteArtistHandler = async () => {
    const response = await delete_request(
      navigate,
      `/api/v1/admin/artists/${artistId}`
    );
    console.log("Deleted Artist", response.data);
    navigate("/ArtistList");
  };

  const onCloseModalHandler = () => {
    setShowModal(false);
  };

  return (
    <>
      <TopNav />
      <div id="layoutSidenav">
        <SideNav />
        <div
            id="layoutSidenav_content"
            style={{
              padding: 50,
              display: "flex",
              flexDirection: "column",
              maxWidth: "90%",
              margin: "auto",
              height: 400,
              overflowY: "scroll",
              alignItems: "center",
              justifyContent: "start",
            }}
        >
          <Modal onClose={onCloseModalHandler} show={showModal}>
            <span>Bu resmi silmek istediğinize emin misiniz?</span>
          </Modal>
          <div className="input-group mb-3 d-flex align-center justify-content-center">
            <FileUploader
                file={profileImageFile?.url ? null : profileImageFile}
                defaultUrl={profileImageFile?.url ? profileImageFile.url : null}
                fileType="IMAGE"
                onFileChange={handleProfileFileChange}
                onFileRemove={handleProfileFileRemove}
            />
          </div>
          <div className="input-group mb-3">
            <input
                type="text"
                className="form-control mx-2"
                placeholder="Ad Soyad"
                ref={fullNameRef}
            />
          </div>
          <h5>Sanatçı Özet</h5>
          <div className="container-fluid mb-3">
            <MarkdownEditor
                value={summary}
                onChange={(text) => setSummary(text)}
            />
          </div>
          <h5>Sanatçı Detay</h5>
          <div className="container-fluid mb-3 ">
            <MarkdownEditor
                value={biography}
                onChange={(text) => setBiography(text)}
            />
          </div>
          <h5>Video Linkleri</h5>
          {descriptionVideos.map((video, index) => (
              <div key={index} className="input-group mb-3">
                <input
                    type="text"
                    className="form-control mx-2"
                    placeholder="Video Başlığı"
                    value={video.header}
                    onChange={(e) =>
                        handleVideoChange(index, "header", e.target.value)
                    }
                />
                <input
                    type="text"
                    className="form-control mx-2"
                    placeholder="Video URL"
                    value={video.url}
                    onChange={(e) =>
                        handleVideoChange(index, "url", e.target.value)
                    }
                />
                <button
                    className="btn btn-danger mx-2"
                    onClick={() => handleRemoveVideo(index)}
                >
                  Sil
                </button>
              </div>
          ))}
          <h5>Eserler</h5>
          <div className={classes.artwork_container}>
            {files?.map((file, index) => (
                <FileUploader
                    key={index}
                    file={file?.url ? null : file}
                    index={index}
                    fileType={
                      file &&
                      (file.type.includes("image") || file.type.includes("IMAGE"))
                          ? "IMAGE"
                          : "VIDEO"
                    }
                    defaultUrl={file?.url}
                    onFileChange={handleFileChange}
                    onFileRemove={handleFileRemove.bind(
                        this,
                        index,
                        file?.id ?? null
                    )}
                />
            ))}
          </div>
          <div className="d-flex flex-row">
            <button
                onClick={onSaveHandler}
                className="mt-4 mx-2 btn btn-success"
                style={{ backgroundColor: isSaving ? "orange" : "#28a745", width: "200px"}}
                disabled={isSaving}
            >
              {isSaving ? "kaydediliyor..." : "kaydet"}
            </button>
            <button
                onClick={onDeleteArtistHandler}
                className="mt-4 mx-2 btn btn-danger"
                style={{width: 200}}
            >
              sil
            </button>
          </div>
          <div
              className="mt-4 p-3 bg-warning text-dark"
              style={{borderRadius: "5px", maxWidth: "90%", margin: "auto"}}
          >
            <h5>Dikkat Edilmesi Gerekenler:</h5>
            <ul style={{paddingLeft: "20px"}}>
              <li>Sanatçı kapak fotoğrafı silinemez, sadece değiştirilebilir.</li>
              <li>
                <b>İsim</b>, <b>Sanatçı Özet</b>, <b>Sanatçı Detay</b> ve <b>Video
                Linkleri</b> kısımları <b>Güncelle</b> butonu ile güncellenmektedir.
                Değişikliklerden sonra <b>Güncelle</b> butonuna
                basılması gerekmektedir.
              </li>
              <li>
                <b>Güncelle</b> butonu kullanılmadan önce <b>Video Linkleri</b>'ndeki son boş alanın silinmesi
                gerekmektedir. Aksi durumda sitede boş alan oluşacaktır.
              </li>
              <li>
                <b>Video Linkleri</b>'ne sınırsız koyma hakkı bulunmaktadır ancak tavsiye edilmez :). Her link
                eklendikten sonra boş alan açılır.
              </li>
              <li>
                <b>Sanatçı Özet</b> ve <b>Sanatçı Detay</b> alanlarını kutucukların sağ altından
                istediğiniz kadar büyütebilirsiniz.
              </li>
              <li>
                Görseller her değişiklikte otomatik olarak güncellenir. Silme işlemini 2
                kere düşünün derim :))
              </li>
              <li>
                Görsellerin boyutları en fazla <b>300 kb</b> olması site sağlığı için önemlidir.
                Olabildiğince az video yüklenmesi tercih edilmelidir.
              </li>
              <li>
                Yüklenen görsellerin isimleri belli bir düzen içinde olması SEO için
                önemlidir. Örneğin:
                <ul>
                  <li>kapak-Sanatçıİsisim-SanatçıSoyisim</li>
                  <li>eser-Sanatçıİsim-SanatçıSoyisim-Eserİsim-1</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateArtist;
