import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MarkdownEditor from "../components/MarkdownEditor"; // Markdown editörünüzün yolu
import { post_request } from "../utils/ApiHandlers";
import 'react-markdown-editor-lite/lib/index.css';
import TopNav from "../UI/navbars/TopNav";
import SideNav from "../UI/navbars/SideNav";
import toastr from "toastr";
import FileUploader from "../components/FileUploader";
import BlogEditor from "../components/BlogEditor";

const CreateBlog = () => {
    const navigate = useNavigate();
    const [content, setContent] = useState("");
    const [name, setName] = useState("");
    const [keywords, setKeywords] = useState("");
    const [metaDescription, setMetaDescription] = useState("");
    const [pageTitle, setPageTitle] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [blogImage, setBlogImage] = useState(null); // Added for image handling

    const handleFileChange = (file) => {
        setBlogImage(file);
    };

    async function onSaveHandler(event) {
        event.preventDefault();
        setIsSaving(true);

        const formData = new FormData();

        const blogSaveRequest = {
            content,
            name,
            keywords,
            metaDescription,
            pageTitle,
        };

        const blogSaveRequestBlob = new Blob([JSON.stringify(blogSaveRequest)], {
            type: "application/json",
        });

        formData.append("blogSaveRequest", blogSaveRequestBlob);
        formData.append("coverImage", blogImage); // Appending the image

        const response = await post_request(navigate, "/api/v1/admin/blogs", formData, {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
        });

        if (response.status === 200) {
            toastr.success("Blog başarıyla oluşturuldu");
            // You can navigate to the blog list page if needed
            // navigate("/BlogList");
        }

        setIsSaving(false);
    }

    return (
        <>
            <TopNav />
            <div id="layoutSidenav">
                <SideNav />
                <div
                    id="layoutSidenav_content"
                    style={{
                        padding: 50,
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "90%",
                        margin: "auto",
                        height: 400,
                        overflowY: "scroll",
                        alignItems: "center",
                        justifyContent: "start",
                    }}
                >
                    <div className="container d-flex flex-column align-items-center" style={{ width: "80vw" }}>
                        <h2 className="mb-3">Yeni Blog Yazısı</h2>

                        <div className="mb-4">
                            <FileUploader
                                file={blogImage?.url ? null : blogImage}
                                defaultUrl={blogImage?.url ? blogImage.url : null}
                                fileType="IMAGE"
                                onFileChange={handleFileChange}
                                onFileRemove={() => setBlogImage(null)}
                            />
                        </div>

                        <div className="d-flex flex-column align-items-center w-100">
                            <div className="mb-3 w-100">
                                <h5>Blog Adı</h5>
                                <textarea
                                    className="form-control"
                                    rows="2"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Blog Adı"
                                />
                            </div>
                            <div className="mb-5 w-100">
                                <h5>Blog İçeriği</h5>
                                <BlogEditor value={content} onChange={setContent} />
                            </div>
                            <div className="mb-3 w-100">
                                <h5>Anahtar Kelimeler - Meta Keywords</h5>
                                <textarea
                                    className="form-control"
                                    rows="2"
                                    value={keywords}
                                    onChange={(e) => setKeywords(e.target.value)}
                                    placeholder="Anahtar Kelimeler"
                                />
                            </div>
                            <div className="mb-3 w-100">
                                <h5>Açıklama - Meta Description</h5>
                                <textarea
                                    className="form-control"
                                    rows="3"
                                    value={metaDescription}
                                    onChange={(e) => setMetaDescription(e.target.value)}
                                    placeholder="Meta Açıklama"
                                />
                            </div>
                            <div className="mb-3 w-100">
                                <h5>Sayfa Başlığı - Page Titles</h5>
                                <textarea
                                    className="form-control"
                                    rows="2"
                                    value={pageTitle}
                                    onChange={(e) => setPageTitle(e.target.value)}
                                    placeholder="Sayfa Başlığı"
                                />
                            </div>
                            <button
                                type="submit"
                                onClick={onSaveHandler}
                                className={`btn ${isSaving ? "btn-warning" : "btn-success"}`}
                                style={{
                                    width: "150px",
                                    alignItems: "center",
                                    backgroundColor: isSaving ? "#ff9800" : "#28a745",
                                    borderColor: isSaving ? "#ff9800" : "#28a745",
                                }}
                                disabled={isSaving}
                            >
                                {isSaving ? "Kaydediliyor..." : "Kaydet"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateBlog;
