import React, { useEffect, useState } from "react";
import classes from "./AuctionList.module.scss";
import SideNav from "../UI/navbars/SideNav";
import TopNav from "../UI/navbars/TopNav";
import AuctionCard from "../components/AuctionCard";
import { get_request } from "../utils/ApiHandlers";
import { useNavigate } from "react-router-dom";

const AuctionList = () => {
    const navigate = useNavigate();
    const [auctions, setAuctions] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize] = useState(20); // Default size per page
    const [hasMoreData, setHasMoreData] = useState(false);

    useEffect(() => {
        fetchAuctions(currentPage);
    }, []);

    const fetchAuctions = async (page) => {
        const response = await get_request(
            navigate,
            `/api/v1/auctions/pasts?page=${page}&size=${pageSize}`
        );
        if (response.data) {
            setAuctions((prevAuctions) => [...prevAuctions, ...response.data.content]);

            // Update hasMoreData based on the number of auctions fetched
            setHasMoreData(response.data.content.length === pageSize);
        } else {
            setHasMoreData(false);
        }
    };

    const handleLoadMore = () => {
        const nextPage = currentPage + 1;
        if (hasMoreData) {
            fetchAuctions(nextPage);
            setCurrentPage(nextPage);
        }
    };

    return (
        <>
            <TopNav />
            <div id="layoutSidenav">
                <SideNav />
                <div
                    id="layoutSidenav_content"
                    style={{
                        padding: 20,
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "90%",
                        margin: "auto",
                        overflowY: "scroll",
                        justifyContent: "start",
                    }}
                >
                    {auctions && (
                        <div className={classes.auction_container}>
                            {auctions.map((auction) => (
                                <AuctionCard
                                    key={auction.id}
                                    id={auction.id}
                                    name={auction.name}
                                    start_date={auction.start_date}
                                    muzayede_no={auction.auctionNo}
                                    end_date={auction.end_date}
                                    img={auction.coverAsset}
                                />
                            ))}
                        </div>
                    )}
                    {hasMoreData && (
                        <div className={classes.load_more_controls}>
                            <button className="btn btn-secondary" onClick={handleLoadMore}>
                                Daha Fazla Yükle
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default AuctionList;
