import React from "react";

const Modal = ({ show, title, children, onClose, onSave }) => {
  return (
    <div
      className={`modal fade ${show ? "show d-block" : "d-none"}`}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden={!show}
      style={show ? { backgroundColor: "rgba(0,0,0,0.5)" } : {}}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {title}
            </h5>
            <button
              type="button"
              className="close"
              onClick={onClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Kapat
            </button>
            <button type="button" className="btn btn-primary" onClick={onSave}>
              Onayla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
