import toastr from "toastr";

export function handleApiError(error, navigate) {
    const message = error.response.data.message || 'An error occurred.';
    if (error.response) {

        toastr.error((error.response.data.errors && error.response.data.errors.length > 0) ?  error.response.data.errors[0] : message);
        if (error.response.status === 401 || error.response.status === 403) {
            window.location.replace('/');
        }

    } else {
        toastr.error('An unexpected error occurred.');
    }
}