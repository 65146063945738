import React, { useEffect, useRef, useState } from "react";
import classes from "./UpdateProduct.module.scss";
import SideNav from "../UI/navbars/SideNav";
import TopNav from "../UI/navbars/TopNav";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-image-upload/dist/index.css";
import {delete_request, get_request, post_request, put_request} from "../utils/ApiHandlers";
import { useNavigate, useParams } from "react-router-dom";
import SelectDropdown from "../components/SelectDropdown";
import FileUploader from "../components/FileUploader";
import MarkdownEditor from "../components/MarkdownEditor";

class Artist {
    constructor(label, id) {
        this.label = label;
        this.id = id;
    }
}

const UpdateProduct = () => {
    const navigate = useNavigate();
    const { productId } = useParams();
    const [coverImageFile, setCoverImageFile] = useState(null);
    const [selectedArtistId, setSelectedArtistId] = useState(null);
    const [artistOptions, setArtistOptions] = useState();
    const [files, setFiles] = useState([null]);
    const [description, setDescription] = useState("")

    const productNameRef = useRef();
    const priceRef = useRef();
    const categoryRef = useRef();

    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        const fetchArtists = async () => {
            const response = await get_request(navigate, `/api/v1/admin/artists/name`);
            const artistsResponse = response.data;
            setArtistOptions(
                artistsResponse.map((artist) => {
                    return new Artist(artist.fullName, artist.id);
                })
            );
            return response;
        };
        fetchArtists();
    }, []);

    useEffect(() => {
        const fetchProduct = async () => {
            const response = await get_request(navigate, `/api/v1/admin/products/${productId}`);
            const product = response.data;

            productNameRef.current.value = product.name;
            setDescription(product.description);
            priceRef.current.value = product.price;
            categoryRef.current.value = product.category;
            setCoverImageFile(product.coverImage);
            setFiles(
                product.mediaAssets?.length > 0 ? [...product.mediaAssets, null] : [null]
            );
            setSelectedArtistId(product.artistId);
        };
        fetchProduct();
    }, [productId]);

    const handleCoverImageChange = async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        const response = await put_request(
            navigate,
            `/api/v1/admin/assets/${coverImageFile?.id}`,
            formData,
            {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
            }
        );
        setCoverImageFile(response.data);
    };

    const handleCoverImageRemove = async () => {
        const response = await delete_request(
            navigate,
            `/api/v1/admin/assets/${coverImageFile?.id}`
        );
        if (response.status === 200) {
            setCoverImageFile(null);
        }
    };

    const handleFileChange = async (file, index) => {
        let response;
        if (files[index]?.id) {
            const formData = new FormData();
            formData.append("file", file);
            response = await put_request(
                navigate,
                `/api/v1/admin/assets/${files[index].id}`,
                formData,
                {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                }
            );
        } else {
            const formData = new FormData();
            formData.append("file", file);
            response = await post_request(
                navigate,
                `/api/v1/admin/assets/${productId}`,
                formData,
                {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                }
            );
        }
        const newFiles = [...files];
        newFiles[index] = response.data;

        if (index === files.length - 1) {
            newFiles.push(null);
        }

        setFiles(newFiles);
    };

    const handleFileRemove = async (index, imageId) => {
        const response = await delete_request(
            navigate,
            `/api/v1/admin/assets/${imageId}`
        );
        if (response.status === 200) {
            const newFiles = files.filter((_, i) => i !== index);
            if (newFiles.length === 0) {
                newFiles.push(null);
            }
            setFiles(newFiles);
        }
    };

    const onSetSelectedArtist = (id) => {
        setSelectedArtistId(id);
    };

    async function onSaveHandler(event) {
        setIsSaving(true);
        event.preventDefault();
        const productUpdateRequest = {
            name: productNameRef.current.value,
            description: description,
            price: priceRef.current.value,
            category: categoryRef.current.value,
            artistId: selectedArtistId,
        };

        await put_request(
            navigate,
            `/api/v1/admin/products/${productId}`,
            productUpdateRequest,
            {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            }
        );

        setIsSaving(false)

    }

    async function onDeleteHandler(event) {
        event.preventDefault();
        const response = await delete_request(
            navigate,
            `/api/v1/admin/products/${productId}`
        );
        if (response.status === 200) {
            navigate(`/products`);
        }
    }

    return (
        <>
            <TopNav />
            <div id="layoutSidenav">
                <SideNav />
                <div
                    id="layoutSidenav_content"
                    style={{
                        padding: 50,
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "90%",
                        margin: "auto",
                        height: 400,
                        overflowY: "scroll",
                        alignItems: "center",
                        justifyContent: "start",
                    }}
                >
                    <div className="input-group mb-3 d-flex align-center justify-content-center">
                        <FileUploader
                            file={coverImageFile?.url ? null : coverImageFile}
                            defaultUrl={coverImageFile?.url ? coverImageFile.url : null}
                            fileType="IMAGE"
                            onFileChange={handleCoverImageChange}
                            onFileRemove={handleCoverImageRemove}
                        />
                    </div>
                    <div className="input-group mb-3 ">
                        <input
                            type="text"
                            className="form-control mx-2"
                            id="basic-url"
                            placeholder="Eser Adı"
                            aria-describedby="basic-addon3"
                            ref={productNameRef}
                        />
                        <input
                            type="text"
                            className="form-control mx-2"
                            id="basic-url2"
                            placeholder="Fiyat"
                            aria-describedby="basic-addon3"
                            ref={priceRef}
                        />
                    </div>
                    <div className="input-group mb-3 ">
                        <input
                            type="text"
                            className="form-control mx-2"
                            id="basic-url2"
                            placeholder="Kategori"
                            aria-describedby="basic-addon3"
                            ref={categoryRef}
                        />
                        <SelectDropdown
                            width="300px"
                            margin="0 6px"
                            selectedOption={selectedArtistId}
                            placeholder="Sanatçı seçiniz"
                            options={artistOptions}
                            onSetSelectedArtist={onSetSelectedArtist}
                        />
                    </div>
                    <h5>Eser Detay</h5>
                    <div className="container-fluid mb-3 ">
                        <MarkdownEditor
                            value={description}
                            onChange={(text) => setDescription(text)}
                        />
                    </div>

                    <div className={classes.image_grid_container}>
                        {files.map((file, index) => (
                            <FileUploader
                                key={index}
                                file={file?.url ? null : file}
                                index={index}
                                fileType={
                                    file &&
                                    (file.type.includes("image") || file.type.includes("IMAGE"))
                                        ? "IMAGE"
                                        : "VIDEO"
                                }
                                defaultUrl={file?.url}
                                onFileChange={handleFileChange}
                                onFileRemove={handleFileRemove.bind(this, index, file?.id ?? null)}
                            />
                        ))}
                    </div>
                    <div className="d-flex flex-row">
                        <button
                            onClick={onSaveHandler}
                            className="mt-2 mx-2 btn btn-success"
                            style={{ backgroundColor: isSaving ? "orange" : "#28a745", width: "200px"}}
                            disabled={isSaving}
                        >
                            {isSaving ? "kaydediliyor..." : "kaydet"}
                        </button>
                        <button
                            onClick={onDeleteHandler}
                            className="mt-2 mx-2 btn btn-danger"
                            style={{width: 200}}
                        >
                            sil
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpdateProduct;
