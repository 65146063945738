import React, { useEffect, useRef, useState } from "react";
import classes from "./CreateAuctionProduct.module.scss";
import SideNav from "../UI/navbars/SideNav";
import TopNav from "../UI/navbars/TopNav";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-image-upload/dist/index.css";
import { get_request, post_request } from "../utils/ApiHandlers";
import { useNavigate, useParams } from "react-router-dom";
import SelectDropdown from "../components/SelectDropdown";
import FileUploader from "../components/FileUploader";

class Artist {
  constructor(label, id) {
    this.label = label;
    this.id = id;
  }
}

const CreateAuctionProduct = () => {
  const navigate = useNavigate();
  const { auctionId } = useParams();
  const [selectedArtistId, setSelectedArtistId] = useState(null);
  const [artistOptions, setArtistOptions] = useState();
  const [coverImage, setCoverImage] = useState(null);
  const [files, setFiles] = useState([null]);
  const [isSaving, setIsSaving] = useState(false);


  useEffect(() => {
    const fetchArtists = async () => {
      const response = await get_request(
        navigate,
        `/api/v1/admin/artists/name`
      );
      const artistsResponse = response.data;
      setArtistOptions(
        artistsResponse?.map((artist) => {
          return new Artist(artist.fullName, artist.id);
        })
      );
      return response;
    };
    fetchArtists();
  }, []);

  const auctionProductNameRef = useRef();
  const auctionProductDescriptionNameRef = useRef();
  const initialPriceRef = useRef();

  const handleCoverImage = (file) => {
    setCoverImage(file);
  }
  const handleFileChange = (file, index) => {
    const newFiles = [...files];
    newFiles[index] = file;

    if (index === files.length - 1) {
      newFiles.push(null);
    }
    setFiles(newFiles);
  };

  const handleFileRemove = (index) => {
    const newFiles = files.filter((_, i) => i !== index);
    if (newFiles.length === 0) {
      newFiles.push(null);
    }

    setFiles(newFiles);
  };

  const onSetSelectedArtist = (id) => {
    setSelectedArtistId(id);
  };

  async function onSaveHandler(event) {
    setIsSaving(true);
    event.preventDefault();
    const formData = new FormData();

    const auctionSaveRequest = {
      name: auctionProductNameRef.current.value,
      description: auctionProductDescriptionNameRef.current.value,
      initialPrice: initialPriceRef.current.value,
      artistId: selectedArtistId,
      auctionId: auctionId,
    };

    const auctionProductSaveRequestBlob = new Blob(
      [JSON.stringify(auctionSaveRequest)],
      {
        type: "application/json",
      }
    );

    formData.append("lotSaveRequest", auctionProductSaveRequestBlob);
    formData.append("coverImage", coverImage);
    files.forEach((file, index) => {
      if (file) {
        formData.append(`assets`, file);
      }
    });

    const response = await post_request(
      navigate,
      "/api/v1/admin/lots",
      formData,
      {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      }
    );
    setIsSaving(false);

    if (response.status === 200) {
      navigate(`/AuctionProducts/${auctionId}`);
    }
  }

  return (
    <>
      <TopNav />
      <div id="layoutSidenav">
        <SideNav />
        <div
            id="layoutSidenav_content"
            style={{
              padding: 50,
              display: "flex",
              flexDirection: "column",
              maxWidth: "90%",
              margin: "auto",
              height: 400,
              overflowY: "scroll",
              alignItems: "center",
              justifyContent: "start",
            }}
        >
          {/*bunun altına yapıştır */}
          <div className="mb-4">
            <FileUploader
                file={coverImage?.url ? null : coverImage}
                defaultUrl={coverImage?.url ? coverImage.url : null}
                fileType="IMAGE"
                onFileChange={handleCoverImage}
                onFileRemove={() => {
                  setCoverImage(null);
                }}
            />
          </div>
          <div className="input-group mb-3 ">
            <input
                type="text"
                className="form-control mx-2"
                id="basic-url"
                placeholder="Eser Adı"
                aria-describedby="basic-addon3"
                ref={auctionProductNameRef}
            />
            <input
                type="text"
                className="form-control mx-2"
                id="basic-url2"
                placeholder="Açıklama"
                aria-describedby="basic-addon3"
                ref={auctionProductDescriptionNameRef}
            />
          </div>
          <div className="input-group mb-3 ">
            <input
                type="text"
                className="form-control mx-2"
                id="basic-url2"
                placeholder="Başlanıç Fiyatı"
                aria-describedby="basic-addon3"
                ref={initialPriceRef}
            />
            <SelectDropdown
                selectedOption={selectedArtistId}
                placeholder="Sanatçı seçiniz"
                width={"49%"}
                margin={"0 6px 0 6px"}
                options={artistOptions}
                onSetSelectedArtist={onSetSelectedArtist}
            />
          </div>

          <div className={classes.artwork_container}>
            {files?.map((file, index) => (
                <FileUploader
                    key={index}
                    file={file}
                    index={index}
                    fileType={file && file.type.toUpperCase()}
                    onFileChange={handleFileChange}
                    onFileRemove={handleFileRemove}
                />
            ))}
          </div>
          <button
              onClick={onSaveHandler}
              className="mt-2 btn btn-success"
              style={{backgroundColor: isSaving ? "orange" : "#28a745", width: "200px"}}
              disabled={isSaving}
          >
            {isSaving ? "kaydediliyor..." : "taslağa kaydet"}
          </button>
          <div
              className="mt-4 p-3 bg-warning text-dark"
              style={{borderRadius: "5px", maxWidth: "90%", margin: "auto"}}
          >
            <h5>Dikkat Edilmesi Gerekenler:</h5>
            <ul style={{paddingLeft: "20px"}}>
              <li>
                <b>Ek görseller</b> hariç tüm alanlar doldurulmadan Müzayede Ürünü kayıt edilemez
              </li>
              <li>
                <b>Taslağa Kaydet</b> butonuna tıklanıldıktan sonra kayıt edilen eserleri sol menüde <b>Müzayede >
                Taslak Müzayedeler</b> > <b>İlgili Müzayede</b> > <b>Eserleri Düzenle</b> içinde yer alır.
                Oradan değişiklikler yapabilir ya da silebilirsiniz.
              </li>
              <li>
                Görsellerin boyutları en fazla <b>300 kb</b> olması site sağlığı için önemlidir.
              </li>
              <li>
                İstenilen kadar <b>Ürün Görseli</b> ya da video ekleyebilirsiniz ancak fazla görselin siteyi yavaşlatma
                potansiyeli vardır.
              </li>
              <li>
                Görsellerin boyutları en fazla <b>300 kb</b> olması site sağlığı için önemlidir.
                Olabildiğince az video yüklenmesi tercih edilmelidir.
              </li>
              <li>
                Yüklenen görsellerin isimleri belli bir düzen içinde olması SEO için
                önemlidir. Örneğin:
                <ul>
                  <li>kapak-Ürünİsim-Müzayedeİsim-SanatçıSoyisim</li>
                  <li>görsel-Ürünİsim-Müzayedeİsim-SanatçıSoyisim-1</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAuctionProduct;
