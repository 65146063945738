import React, { useEffect, useState } from "react";
import classes from "./AuctionProductCard.module.scss";
import { useNavigate } from "react-router-dom";
import { get_request } from "../utils/ApiHandlers";
import {formatPrice} from "../utils/Formatters";

const AuctionProductCard = (props) => {
  const navigate = useNavigate();

  return (
    <div className={classes.product_card} style={{ height: "50vh" }}>
      <img
        src={props.coverImage && props.coverImage.url}
        //alt={props.assets && props.coverImage.alt}
        className={classes.product_card__img}
      />
      <span className={classes.text_bold}>{props.artistFullName}</span>
      <span className={classes.text_bold}>{props.name}</span>
      <span className={classes.text_normal}>{props.description}</span>
      <span
        style={{
          fontFamily: "Gilroy-SemiBold",
          marginBottom: "2vh",
          fontSize: 18,
          letterSpacing: 1.5,
        }}
      >
        {formatPrice(props.currentPrice.toString())} TL
      </span>
      <div className={classes.product_card__btn_container}>
        <div
          onClick={() => {
            navigate(`/UpdateAuctionProduct/${props.id}`);
          }}
          className={classes.banner}
        >
          DÜZENLE
        </div>
      </div>
    </div>
  );
};

export default AuctionProductCard;


