import React, {useEffect, useState} from "react";
import classes from "./FinishedProducts.module.scss";
import AuctionProductSaleCard from "../components/AuctionProductSaleCard";
import TopNav from "../UI/navbars/TopNav";
import SideNav from "../UI/navbars/SideNav";
import {get_request} from "../utils/ApiHandlers";
import {useNavigate, useParams} from "react-router-dom";
import FinishedLotCard from "../components/finishedProducts/FinishedLotCard";

const FinishedProducts = () => {
    const navigate = useNavigate();
    const {auctionId} = useParams();
    const [products, setProducts] = useState([]);

    const [currentPage, setCurrentPage] = useState(0);
    const [hasMoreData, setHasMoreData] = useState(false);

    useEffect(() => {
        fetchFinishedLots(currentPage)
    }, [])

    const fetchFinishedLots = async (nextpage) => {
        const response = await get_request(navigate, `/api/v1/admin/auctions/${auctionId}/sales?page=${nextpage}&size=18`);
        if (response.data && response.status === 200) {
            console.log(response.data.content)
            setProducts((prevProducts) => [...prevProducts, ...response.data.content]);
            setHasMoreData(response.data.page.totalPages > nextpage + 1)
        }
    }

    const handleLoadMore = () => {
        const nextPage = currentPage + 1;
        if (hasMoreData) {
            setCurrentPage(nextPage);
            fetchFinishedLots(nextPage);
        }
    };

    return (
        <>
            <TopNav />
            <div id="layoutSidenav">
                <SideNav />
                <div
                    id="layoutSidenav_content"
                    style={{
                        padding: 20,
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "90%",
                        margin: "auto",
                        height: 400,
                        overflowY: "scroll",
                        justifyContent: "start",
                    }}
                >   {products && (
                    <>
                    <div className={classes.finished_products}>
                        {products && products.length > 0 ? (
                            products.map((product) => (
                                <FinishedLotCard
                                    lot = {product && product}
                                />
                            ))
                        ) : (
                            <p>No finished products available.</p>
                        )}
                    </div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                    <button class="btn btn-info"
                            style={{
                                display: `${hasMoreData ? "block" : "none"}`,
                                marginTop: "20px",
                                width: "200px"
                            }}
                            onClick={handleLoadMore}
                                >
                        daha fazla yükle
                        </button>
                    </div>
                    </>
                )}
                </div>
            </div>
        </>
    );
};

export default FinishedProducts;
