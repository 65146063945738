import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { get_request } from "../../utils/ApiHandlers";

const SideNav = (props) => {
  const [drafts, setDrafts] = useState(null);
  const [ready, setReady] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDraftAuctions = async () => {
      const response = await get_request(navigate, `/api/v1/admin/auctions/drafts`);
      setDrafts(response.data);
      return response;
    };
    const fetchReadyAuctions = async () => {
      const response = await get_request(navigate, `/api/v1/admin/auctions/ready`);
      setReady(response.data);
      return response;
    };
    fetchDraftAuctions();
    fetchReadyAuctions();
  }, [props.trigger]);

  return (
    <div id="layoutSidenav_nav">
      <nav
        className="sb-sidenav accordion sb-sidenav-dark"
        id="sidenavAccordion"
      >
        <div className="sb-sidenav-menu">
          <div className="nav">
            <div className="sb-sidenav-menu-heading">MÜZAYEDE</div>
            <a
                className="nav-link collapsed"
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapsePages"
                aria-expanded="true"
                aria-controls="collapsePages"
                id="collapse-main"
            >
              <div className="sb-nav-link-icon">
                <i className="fa-solid fa-cart-shopping"></i>
              </div>
              Müzayede
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down"></i>
              </div>
            </a>
            <div className="collapse" id="collapsePages">
              <nav className="sb-sidenav-menu-nested nav">
                <a
                    className="nav-link link"
                    onClick={() => {
                      navigate("/AuctionList");
                    }}
                    style={{cursor: "pointer"}}
                >
                  <div className="sb-nav-link-icon">
                    <i className="fa-solid fa-list"></i>
                  </div>
                  Müzayedelerim
                </a>
                <a
                    className="nav-link collapsed"
                    href="#"
                    data-bs-toggle="collapse"
                    data-bs-target="#pagesCollapseError"
                    aria-expanded="false"
                    aria-controls="pagesCollapseError"
                >
                  <div className="sb-nav-link-icon">
                    <i className="fa-brands fa-firstdraft"></i>
                  </div>
                  Taslak Müzayedeler
                  <div className="sb-sidenav-collapse-arrow">
                    <i className="fas fa-angle-down"></i>
                  </div>
                </a>
                <div
                    className="collapse"
                    id="pagesCollapseError"
                    aria-labelledby="headingOne"
                >
                  <nav className="sb-sidenav-menu-nested nav">
                    {drafts &&
                        drafts.map((draft) => {
                          return (
                              <a
                                  key={draft.id}
                                  className="nav-link"
                                  style={{cursor: "pointer"}}
                                  onClick={() => {
                                    navigate(`/UpdateAuction/${draft.id}`);
                                  }}
                              >
                                <div className="sb-nav-link-icon">
                                  <i className="fa-solid fa-cart-shopping"></i>
                                </div>
                                {draft.name}
                              </a>
                          );
                        })}
                  </nav>

                </div>
                <a
                    className="nav-link collapsed"
                    href="#"
                    data-bs-toggle="collapse"
                    data-bs-target="#pagesCollapseReady"
                    aria-expanded="false"
                    aria-controls="pagesCollapseReady"
                >
                  <div className="sb-nav-link-icon">
                    <i className="fa-brands fa-firstdraft"></i>
                  </div>
                  Hazır Müzayedeler
                  <div className="sb-sidenav-collapse-arrow">
                    <i className="fas fa-angle-down"></i>
                  </div>
                </a>
                <div
                    className="collapse"
                    id="pagesCollapseReady"
                    aria-labelledby="headingOne"
                >
                  <nav className="sb-sidenav-menu-nested nav">
                    {ready &&
                        ready.map((ready) => {
                          return (
                              <a
                                  key={ready.id}
                                  className="nav-link"
                                  style={{cursor: "pointer"}}
                                  onClick={() => {
                                    navigate(`/UpdateAuction/${ready.id}`);
                                  }}
                              >
                                <div className="sb-nav-link-icon">
                                  <i className="fa-solid fa-cart-shopping"></i>
                                </div>
                                {ready.name}
                              </a>
                          );
                        })}
                  </nav>

                </div>
                <a
                    className="nav-link link"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                      navigate("/CreateAuction");
                    }}
                >
                  <div className="sb-nav-link-icon">
                    <i className="fa-solid fa-plus"/>
                  </div>
                  Yeni Müzayede Ekle
                </a>
              </nav>
            </div>

            <div className="sb-sidenav-menu-heading">Sanatçılar</div>
            <a
                className="nav-link collapsed"
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseLayouts"
                aria-expanded="false"
                aria-controls="collapseLayouts"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-columns"></i>
              </div>
              Sanatçı
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down"></i>
              </div>
            </a>
            <div
                className="collapse"
                id="collapseLayouts"
                aria-labelledby="headingOne"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <a
                    className="nav-link"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                      navigate("/ArtistList");
                    }}
                >
                  Sanatçılar
                </a>
                <a
                    className="nav-link"
                    onClick={() => {
                      navigate("/CreateArtist");
                    }}
                    style={{cursor: "pointer"}}
                >
                  Yeni Sanatçı Ekle
                </a>
              </nav>
            </div>

            <div className="sb-sidenav-menu-heading">Hemen Al</div>
            <a
                className="nav-link collapsed"
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseProducts"
                aria-expanded="true"
                aria-controls="collapseProducts"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-columns"></i>
              </div>
              Hemen Al
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down"></i>
              </div>
            </a>
            <div className="collapse" id="collapseProducts" aria-labelledby="headingOne">
              <nav className="sb-sidenav-menu-nested nav">
                <a className="nav-link" style={{cursor: "pointer"}} onClick={() => {
                  navigate("/ProductList");
                }}>
                  Ürünler
                </a>
                <a className="nav-link" style={{cursor: "pointer"}} onClick={() => {
                  navigate("/ProductRequests")
                }}>
                  Siparişler
                </a>
                <a className="nav-link" onClick={() => {
                  navigate("/CreateProduct");
                }} style={{cursor: "pointer"}}>
                  Yeni Ürün Ekle
                </a>
              </nav>
            </div>
            <div className="sb-sidenav-menu-heading">Blog</div>
            <a
                className="nav-link collapsed"
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseBlog"
                aria-expanded="true"
                aria-controls="collapseBlog"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-columns"></i>
              </div>
              Blog
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down"></i>
              </div>
            </a>
            <div className="collapse" id="collapseBlog" aria-labelledby="headingOne">
              <nav className="sb-sidenav-menu-nested nav">
                <a className="nav-link" style={{cursor: "pointer"}} onClick={() => {
                  navigate("/BlogList");
                }}>
                  Bloglar
                </a>
                <a className="nav-link" onClick={() => {
                  navigate("/CreateBlog");
                }} style={{cursor: "pointer"}}>
                  Blog ekle
                </a>
              </nav>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

SideNav.propTypes = {
  trigger: PropTypes.any,
};

export default SideNav;
