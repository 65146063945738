import React, { useEffect, useState } from "react";
import classes from "./ArtistList.module.scss";
import SideNav from "../UI/navbars/SideNav";
import TopNav from "../UI/navbars/TopNav";
import { get_request } from "../utils/ApiHandlers";
import { useNavigate } from "react-router-dom";
import ArtistCard from "../components/ArtistCard";

const ArtistList = () => {
    const navigate = useNavigate();
    const [artists, setArtists] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize] = useState(20); // Set the desired page size
    const [hasMoreData, setHasMoreData] = useState(false);

    useEffect(() => {
        fetchArtists(currentPage);
    }, []);

    const fetchArtists = async (page) => {
        const response = await get_request(
            navigate,
            `/api/v1/admin/artists?page=${page}&size=${pageSize}`
        );
        if (response.data) {
            // Append new artists to the existing list
            setArtists((prevArtists) => [...prevArtists, ...response.data.content]);

            // Update hasMoreData based on the number of artists fetched
            setHasMoreData(response.data.content.length === pageSize);
        } else {
            setHasMoreData(false);
        }
    };

    const handleLoadMore = () => {
        const nextPage = currentPage + 1;
        if (hasMoreData) {
            fetchArtists(nextPage);
            setCurrentPage(nextPage);
        }
    };

    return (
        <>
            <TopNav />
            <div id="layoutSidenav">
                <SideNav />
                <div
                    id="layoutSidenav_content"
                    style={{
                        padding: 20,
                        maxWidth: "90%",
                        margin: "auto",
                        height: 400,
                        overflowY: "scroll",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                    }}
                >
                    <button
                        onClick={() => {
                            navigate(`/CreateArtist`);
                        }}
                        className="btn btn-primary w-25 mx-auto mb-3"
                    >
                        Yeni Sanatçı Ekle
                    </button>
                    {artists && (
                        <div className={classes.artist_container}>
                            {artists.map((artist) => (
                                <ArtistCard
                                    id={artist.id}
                                    key={artist.id}
                                    imageUrl={artist.profileImage.url}
                                    fullName={artist.fullName}
                                />
                            ))}
                        </div>
                    )}
                    {hasMoreData && (
                        <button
                            onClick={handleLoadMore}
                            className="btn btn-secondary w-25 mx-auto my-3"
                        >
                            Daha Fazla Yükle
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};

export default ArtistList;
