import React from "react";

const Modal = ({ show, title, children, onClose, onSave }) => {
    return (
        <div
            className={`modal fade ${show ? "show d-block" : "d-none"}`}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden={!show}
            style={{
                zIndex: 1050, /* Ensure high zIndex */
                position: "absolute", /* Ensure it's fixed in place */
                backgroundColor: show ? "rgba(0,0,0,0.5)" : "transparent"
            }}
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            {title}
                        </h5>
                    </div>
                    <div className="modal-body">{children}</div>
                    <div className="modal-footer d-flex justify-content-around">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={onClose}
                        >
                            Kapat
                        </button>
                        <button type="button" className="btn btn-primary" onClick={onSave}>
                            Onayla
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
