import React, { useRef, useState } from "react";
import classes from "./CreateArtist.module.scss";
import SideNav from "../UI/navbars/SideNav";
import TopNav from "../UI/navbars/TopNav";
import FileUploader from "../components/FileUploader";
import { post_request } from "../utils/ApiHandlers";
import {useNavigate} from "react-router-dom";

import 'react-markdown-editor-lite/lib/index.css';
import MarkdownEditor from "../components/MarkdownEditor";


const CreateArtist = () => {
  const navigate = useNavigate();
  const [files, setFiles] = useState([null]);
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [descriptionVideos, setDescriptionVideos] = useState([{ header: "", url: "" }]);
  const [isSaving, setIsSaving] = useState(false);

  const handleProfileFileChange = (file) => {
    setProfileImageFile(file);
  };

  const handleFileChange = (file, index) => {
    const newFiles = [...files];
    newFiles[index] = file;

    if (index === files.length - 1) {
      newFiles.push(null);
    }

    setFiles(newFiles);
  };

  const handleFileRemove = (index) => {
    const newFiles = files.filter((_, i) => i !== index);
    if (newFiles.length === 0) {
      newFiles.push(null);
    }

    setFiles(newFiles);
  };

  const handleProfileFileRemove = () => {
    setProfileImageFile(null);
  };

  const handleVideoChange = (index, field, value) => {
    const newVideos = [...descriptionVideos];
    newVideos[index][field] = value;

    if (newVideos[index].header && newVideos[index].url && index === descriptionVideos.length - 1) {
      newVideos.push({ header: "", url: "" });
    }

    setDescriptionVideos(newVideos);
  };

  const handleRemoveVideo = (index) => {
    const newVideos = descriptionVideos.filter((_, i) => i !== index);
    setDescriptionVideos(newVideos.length === 0 ? [{ header: "", url: "" }] : newVideos);
  };

  const fullNameRef = useRef();
  const [summary, setSummary] = useState("");
  const [biography, setBiography] = useState("");
  async function onSaveHandler(event) {
    event.preventDefault();
    setIsSaving(true);

    const formData = new FormData();
    const artistSaveRequest = {
      fullName: fullNameRef.current.value,
      summary: summary, // Get markdown content
      biography: biography, // Get markdown content
      descriptionVideos: descriptionVideos.filter(video => video.header && video.url),
    };

    const artistSaveRequestBlob = new Blob(
        [JSON.stringify(artistSaveRequest)],
        {
          type: "application/json",
        }
    );

    formData.append(`profileImage`, profileImageFile);
    files.forEach((file, index) => {
      if (file) {
        formData.append(`assets`, file);
      }
    });

    formData.append("artistSaveRequest", artistSaveRequestBlob);

    const response = await post_request(navigate, "/api/v1/admin/artists", formData, {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    });
    setIsSaving(false);
    if (response.status === 200) {
      navigate("/ArtistList");
    }
  }

  return (
      <>
        <TopNav />
        <div id="layoutSidenav">
          <SideNav/>
          <div
              id="layoutSidenav_content"
              style={{
                padding: 50,
                display: "flex",
                flexDirection: "column",
                maxWidth: "90%",
                margin: "auto",
                height: 400,
                overflowY: "scroll",
                alignItems: "center",
                justifyContent: "start",
              }}
          >
            <div className="input-group mb-3 d-flex align-center justify-content-center">
              <FileUploader
                  file={profileImageFile}
                  onFileChange={handleProfileFileChange}
                  onFileRemove={handleProfileFileRemove}
              />
            </div>
            <h5>Ad Soyad</h5>
            <div className="container-fluid mb-3">
              <input
                  type="text"
                  className="form-control"
                  placeholder="Ad Soyad"
                  ref={fullNameRef}
              />
            </div>
            <h5>Sanatçı Özet</h5>
            <div className="container-fluid mb-3">
              <MarkdownEditor
                  value={summary}
                  onChange={(text) => setSummary(text)}
              />
            </div>
            <h5>Sanatçı Detay</h5>
            <div className="container-fluid mb-3 ">
              <MarkdownEditor
                  value={biography}
                  onChange={(text) => setBiography(text)}
              />
            </div>
          <h5>Video Linkleri</h5>
          {descriptionVideos.map((video, index) => (
              <div key={index} className="input-group mb-3">
                <input
                    type="text"
                    className="form-control mx-2"
                    placeholder="Video Başlığı"
                    value={video.header}
                    onChange={(e) => handleVideoChange(index, "header", e.target.value)}
                />
                <input
                    type="text"
                    className="form-control mx-2"
                    placeholder="Video Url"
                    value={video.url}
                    onChange={(e) => handleVideoChange(index, "url", e.target.value)}
                />
                {index > 0 && (
                    <button
                        className="btn btn-danger mx-2"
                        onClick={() => handleRemoveVideo(index)}
                    >
                      kaldır
                    </button>
                )}
              </div>
          ))}
          <h5>Eserler</h5>
          <div className={classes.artwork_container}>
            {files.map((file, index) => (
                <FileUploader
                    key={index}
                    file={file}
                    index={index}
                    fileType={file && file.type}
                    onFileChange={handleFileChange}
                    onFileRemove={handleFileRemove}
                />
            ))}
          </div>
          <button
              onClick={onSaveHandler}
              className="mt-2 btn btn-success"
              style={{ backgroundColor: isSaving ? "orange" : "#28a745", width: "200px"}}
              disabled={isSaving}

          >
            {isSaving ? "kaydediliyor..." : "kaydet"}
          </button>
          <div
              className="mt-4 p-3 bg-warning text-dark"
              style={{borderRadius: "5px", maxWidth: "90%", margin: "auto"}}
          >
            <h5>Dikkat Edilmesi Gerekenler:</h5>
            <ul style={{paddingLeft: "20px"}}>
              <li>
                <b>Eserler</b> ve <b>Video Linkleri</b> hariç alanların doldurulması gerekmektedir. Aksi taktirde
                kayıt edilemez.
              </li>
              <li>
                <b>Sanatçı Özet</b> ve <b>Sanatçı Detay</b> alanlarını kutucukların sağ altından
                istediğiniz kadar büyütebilirsiniz.
              </li>
              <li>
                <b>Video Linkleri</b>'ne sınırsız koyma hakkı bulunmaktadır ancak tavsiye edilmez :). Her link
                eklendikten sonra boş alan açılır.
              </li>
              <li>
                İstenilen kadar Eser görseli ya da video ekleyebilirsiniz ancak fazla görselin siteyi yavaşlatma
                potansiyeli vardır.
              </li>
              <li>
                Görsellerin boyutları en fazla <b>300 kb</b> olması site sağlığı için önemlidir.
                Olabildiğince az video yüklenmesi tercih edilmelidir.
              </li>
              <li>
                Yüklenen görsellerin isimleri belli bir düzen içinde olması SEO için
                önemlidir. Örneğin:
                <ul>
                  <li>kapak-Sanatçıİsisim-SanatçıSoyisim</li>
                  <li>eser-Sanatçıİsim-SanatçıSoyisim-Eserİsim-1</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
</>
)
  ;
};

export default CreateArtist;
