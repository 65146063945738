import React, {useState} from "react";
import classes from "./AuctionProductCard.module.scss";
import { useNavigate } from "react-router-dom";
import {post_request} from "../utils/ApiHandlers";
import toastr from "toastr";
import {formatPrice} from "../utils/Formatters";

const RequestCard = (props) => {
    const navigate = useNavigate();
    const [decision, setDecision] = useState(null);

    async function handleApproval(dec) {
        const response = await post_request(navigate, `/api/v1/admin/products/${props.id}/decision?decision=${dec}`, {});
        if (response.status === 200) {
            toastr.success("İşlem başarılı");
            setDecision(dec);
        }
    }

    return (
        <div className={classes.product_card} style={{height: "70vh"}}>
            <img
                src={props.coverImage && props.coverImage.url}
                className={classes.product_card__img}
                alt="Product"
            />
            <span className={classes.text_bold}>{props.name}</span>
            <span className={classes.text_bold}>{props.artistName}</span>
            <span
                style={{
                    fontFamily: "Gilroy-SemiBold",
                    marginBottom: "2vh",
                    fontSize: 18,
                    letterSpacing: 1.5,
                }}
            >
                {formatPrice(props.price.toString())} TL
            </span>
            <span className={classes.text_bold}>sipariş eden:</span>
            <span className={classes.text_bold}>{`${props.buyerName} ${props.buyerSurname}`}</span>
            <span className={classes.text_without_margin}>{props.buyerEmail}</span>
            <span className={classes.text_without_margin}>{props.buyerPhone}</span>
            <span className={classes.text_without_margin}>{props.buyerAddress}</span>
            {decision === null ? (
            <div className={classes.product_card__btn_container}>
                <div
                    onClick={() => {
                        handleApproval(true);
                    }}
                    className={classes.banner}
                >
                    onayla
                </div>
                <div
                    onClick={() => {
                        handleApproval(false);
                    }}
                    className={classes.banner}
                >
                    reddet
                </div>
            </div>
                ) : (
                decision ? (
                    <div className={classes.banner} style={{backgroundColor: "green"}}>
                        onaylandı
                    </div>
                ) : (
                    <div className={classes.banner} style={{backgroundColor: "red"}}>
                        reddedildi
                    </div>
                )
            )}

        </div>
    );
};

export default RequestCard;

