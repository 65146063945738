import { api } from "../api/axios";
import { handleApiError } from "./ErrorHandler";
import toastr from "toastr";

async function refreshToken() {
    try {
        const response = await api.get('/api/v1/auth/refresh', {withCredentials: true});
        localStorage.setItem('token', response.data.token);
        return response.data.token;
    } catch (error) {
        console.error('Token yenileme hatası:', error);
        return null;
    }
}

api.interceptors.response.use(
    response => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            const newToken = await refreshToken();

            if (newToken) {
                originalRequest.headers.Authorization = `Bearer ${newToken}`;
                return api(originalRequest);
            } else {
                handleApiError(error);
            }
        }

        return Promise.reject(error);
    }
);

export function get_request(
  navigate,
  url,
  header = {
    Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
  }
) {
  const response = api
    .get(url, {
      headers: header,
    })
    .catch((e) => {
      handleApiError(e);
      return e.response.data;
    });
  return response;
}

export function post_request(
  navigate,
  url,
  body,
  headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  credentials = true
) {
    if (url !== "/api/v1/auth") {
        headers["Authorization"] = `Bearer ${localStorage.getItem("token") || ""}`;
    }
const response = api
    .post(url, body, {
      headers: headers,
      withCredentials: credentials,
    })
    .catch((e) => {
      handleApiError(e);
      return e.response.data;
    }).then(
          (response) => {
              if (response.status === 200 && url !== "/api/v1/auth") {
                  toastr.success("İşlem Başarılı!");
              }
              return response;
          }
      )
    return response;
}

export function put_request(
  navigate,
  url,
  body = null,
  headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  }
) {
  headers["Authorization"] = `Bearer ${localStorage.getItem("token") || ""}`;
  const response = api
    .put(url, body, {
      headers: headers,
    })
    .catch((e) => {
      handleApiError(e);
      return e.response.data;
    }).then(
          (response) => {
              if (response.status === 200) {
                  toastr.success("İşlem Başarılı!");
              }
              return response;
          }
      )
    return response;
}

export function patch_request(
  navigate,
  url,
  body = null,
  headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  }
) {
  headers["Authorization"] = `Bearer ${localStorage.getItem("token") || ""}`;
  const response = api
    .patch(url, body, {
      headers: headers,
    })
    .catch((e) => {
      handleApiError(e);
      return e.response.data;
    }).then(
        (response) => {
            if (response.status === 200) {
            toastr.success("İşlem Başarılı!");
            }
            return response;
        }
      )
    return response;
}

export function delete_request(
  navigate,
  url,
  headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  }
) {
  headers["Authorization"] = `Bearer ${localStorage.getItem("token") || ""}`;

  const response = api
    .delete(url, {
      headers: headers,
    })
    .catch((e) => {
      handleApiError(e);
      return e.response.data;
    }).then(
        (response) => {
            if (response.status === 200) {
                toastr.success("İşlem Başarılı!");
            }
            return response;
        }
    )
    return response;
}
