import React, {useEffect, useState} from "react";
import classes from "./BlogList.module.scss";
import BlogCard from "../components/blog/BlogCard";
import {get_request} from "../utils/ApiHandlers";
import {useNavigate} from "react-router-dom";
import TopNav from "../UI/navbars/TopNav";
import SideNav from "../UI/navbars/SideNav";

const BlogList = () => {
    const [blogs, setBlogs] = useState(null);
    const navigate = useNavigate();
    const [blogCount, setBlogCount] = useState(0);


    useEffect(() => {
        const fetchBlogs = async () => {
            const response = await get_request(navigate, `/api/v1/admin/blogs`)
            if (response.status === 200) {
                setBlogs(response.data.content);
                setBlogCount(response.data.page.totalElements);
            }
        }
        fetchBlogs()
    }, []);

    return (
        <>
            <TopNav />
            <div id="layoutSidenav">
                <SideNav/>
                <div
                    id="layoutSidenav_content"
                    style={{
                        padding: 50,
                        paddingTop: 20,
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "90%",
                        margin: "auto",
                        height: 400,
                        overflowY: "scroll",
                        alignItems: "center",
                        justifyContent: "start",
                    }}
                >
                    <h5>güncel blog sayısı: {blogCount && blogCount}</h5>
                    <button
                        onClick={() => {
                            navigate(`/CreateBlog`);
                        }}
                        className="btn btn-primary w-25 mx-auto mb-3"
                    >
                        yeni blog ekle
                    </button>
                    <div className={classes.blogContainer}>
                        <div className={classes.blogGrid}>
                            {blogs && blogs.map((blog) => (
                                <BlogCard
                                    key={blog.id}
                                    id={blog.id}
                                    name={blog.name}
                                    coverImage={blog.coverImage}
                                    date={"01.09.2024"}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlogList;
