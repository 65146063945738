import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import "./App.css";
import CreateAuction from "./pages/CreateAuction";
import AuctionProducts from "./pages/AuctionProducts";
import DraftAuctions from "./pages/DraftAuctions";
import UpdateAuction from "./pages/UpdateAuction";
import UpdateAuctionProduct from "./pages/UpdateAuctionProduct";
import CreateAuctionProduct from "./pages/CreateAuctionProduct";
import CreateArtist from "./pages/CreateArtist";
import ArtistList from "./pages/ArtistList";
import AuctionList from "./pages/AuctionList";
import UpdateArtist from "./pages/UpdateArtist";
import CreateProduct from "./pages/CreateProduct";
import ProductList from "./pages/ProductList";
import UpdateProduct from "./pages/UpdateProduct";
import RequestList from "./pages/RequestList";
import FinishedProducts from "./pages/FinishedProducts";
import CreateBlog from "./pages/CreateBlog";
import UpdateBlog from "./pages/UpdateBlog";
import Blog from "./pages/BlogList";
import BlogList from "./pages/BlogList";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/Dashboard" element={<Dashboard />}></Route>
        <Route path="/AuctionList" element={<AuctionList />}></Route>
        <Route path="/CreateAuction" element={<CreateAuction />}></Route>
        <Route path="/AuctionProducts/:auctionId" element={<AuctionProducts />}></Route>
        <Route path="/finishedProducts/:auctionId" element={<FinishedProducts />}></Route>
        <Route path="/DraftAuctions" element={<DraftAuctions />}></Route>
        <Route path="/UpdateAuction/:auctionId" element={<UpdateAuction />}></Route>
        <Route path="/UpdateAuctionProduct/:auctionProductId" element={<UpdateAuctionProduct />}></Route>
        <Route path="/CreateAuctionProduct/:auctionId" element={<CreateAuctionProduct />}></Route>
        <Route path="/CreateArtist" element={<CreateArtist />}></Route>
        <Route path="/ArtistList" element={<ArtistList />}></Route>
        <Route path="/UpdateArtist/:artistId" element={<UpdateArtist />}></Route>
        <Route path="/CreateProduct" element={<CreateProduct />}></Route>
        <Route path="/ProductList" element={<ProductList />}></Route>
        <Route path="/UpdateProduct/:productId" element={<UpdateProduct />}></Route>
        <Route path="/ProductRequests" element={<RequestList />}></Route>
        <Route path="/BlogList" element={<BlogList/>}></Route>
        <Route path="/CreateBlog" element={<CreateBlog />}></Route>
        <Route path="/UpdateBlog/:blogId" element={<UpdateBlog />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
