import React, { useRef, useState } from "react";
import SideNav from "../UI/navbars/SideNav";
import TopNav from "../UI/navbars/TopNav";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { post_request } from "../utils/ApiHandlers";
import FileUploader from "../components/FileUploader";
import {useNavigate} from "react-router-dom";
const CreateAuction = () => {
  const navigate = useNavigate();
  const [auctionImage, setAuctionImage] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isSaving, setIsSaving] = useState(false);

  const auctionNameRef = useRef();
  const auctionDescriptionName = useRef();

  const handleFileChange = (file) => {
    setAuctionImage(file);
  };

  const handleTime = (time) => {
      return (new Date(time - time.getTimezoneOffset()*60*1000)).toISOString();
  };

  async function onSaveHandler(event) {
    setIsSaving(true);
    event.preventDefault();


    const formData = new FormData();

    const auctionSaveRequest = {
        name: auctionNameRef.current.value,
        description: auctionDescriptionName.current.value,
        startTime: handleTime(startDate),
        endTime: handleTime(endDate),
    };

    const auctionSaveRequestBlob = new Blob(
      [JSON.stringify(auctionSaveRequest)],
      {
        type: "application/json",
      }
    );

    formData.append("auctionSaveRequest", auctionSaveRequestBlob);
    formData.append("image", auctionImage);

    const response = await post_request(navigate, "/api/v1/admin/auctions", formData, {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    });

    setTrigger((prev) => !prev);
    if (response.status === 200) {
      // clear data
        auctionNameRef.current.value = "";
        auctionDescriptionName.current.value = "";
        setStartDate(new Date());
        setEndDate(new Date());
        setAuctionImage(null);
    }
    setIsSaving(false);
  }
  return (
    <>
      <TopNav />
      <div id="layoutSidenav">
        <SideNav trigger={trigger} />
          <div
              id="layoutSidenav_content"
              style={{
                  padding: 50,
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "90%",
                  margin: "auto",
                  height: 400,
                  overflowY: "scroll",
                  alignItems: "center",
                  justifyContent: "start",
              }}
          >
              {/*bunun altına yapıştır */}
              <div className="mb-4">
                  <FileUploader
                      file={auctionImage?.url ? null : auctionImage}
                      defaultUrl={auctionImage?.url ? auctionImage.url : null}
                      fileType="IMAGE"
                      onFileChange={handleFileChange}
                      onFileRemove={() => {
                          setAuctionImage(null);
                      }}
                  />
              </div>
              <div className="input-group mb-3 ">
                  <input
                      type="text"
                      className="form-control mx-2"
                      id="basic-url"
                      required={true}
                      placeholder="Müzayede Adı"
                      aria-describedby="basic-addon3"
                      ref={auctionNameRef}
                  />
                  <input
                      type="text"
                      className="form-control mx-2"
                      id="basic-url2"
                      placeholder="Açıklama"
                      required={true}
                      aria-describedby="basic-addon3"
                      ref={auctionDescriptionName}
                  />
              </div>
              <div className="input-group mb-3 d-flex flex-row justify-content-center">
                  <div className="mx-2 d-flex flex-row form-control bg-gradient ">
                      <label for="basic-url" className="form-label mx-3">
                          Başlangıç Tarihi
                      </label>
                      <div className="mb-4">
                          <DateTimePicker onChange={setStartDate} value={startDate}/>
                      </div>
                  </div>
                  <div className="mx-2 d-flex flex-row form-control">
                      <label for="basic-url" className="form-label mx-3">
                          Bitiş Tarihi
                      </label>
                      <div>
                          <DateTimePicker onChange={setEndDate} value={endDate}/>
                      </div>
                  </div>
              </div>
              <button
                  onClick={onSaveHandler}
                  className="mt-2 btn btn-success"
                  style={{backgroundColor: isSaving ? "orange" : "#28a745", width: "200px"}}
                  disabled={isSaving}
              >
                  {isSaving ? "kaydediliyor..." : "taslağa kaydet"}
              </button>
              <div
                  className="mt-4 p-3 bg-warning text-dark"
                  style={{borderRadius: "5px", maxWidth: "90%", margin: "auto"}}
              >
                  <h5>Dikkat Edilmesi Gerekenler:</h5>
                  <ul style={{paddingLeft: "20px"}}>
                      <li>Tüm alanlar doldurulmadan müzayede taslak olarak kayıt edilemez</li>
                      <li>
                          <b>Taslağa Kaydet</b> butonuna tıklanıldıktan sonra kayıt edilen taslaklar sol menüde <b>Müzayede > Taslak Müzayedeler</b> içinde yer alır.
                          Oradan değişiklikler yapabilir ya da silebilirsiniz.
                      </li>
                      <li>
                          Görsellerin boyutları en fazla <b>300 kb</b> olması site sağlığı için önemlidir.
                      </li>
                      <li>
                          Başlangıç ve bitiş tarihleri geçmiş zaman olamaz.
                      </li>
                      <li>
                          Müzayede oluşturduktan sonra taslak müzayede sayfasından müzayedeyi başlamaya hazır yapabilirsiniz.
                      </li>
                      <li>
                      Yüklenen görsellerin isimleri belli bir düzen içinde olması SEO için
                      önemlidir. Örneğin:
                      <ul>
                          <li>kapak-Müzayedeİsim</li>
                      </ul>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
    </>
  );
};

export default CreateAuction;
