import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get_request, put_request, delete_request } from "../utils/ApiHandlers";
import MarkdownEditor from "../components/MarkdownEditor";
import TopNav from "../UI/navbars/TopNav";
import SideNav from "../UI/navbars/SideNav";
import FileUploader from "../components/FileUploader";
import BlogEditor from "../components/BlogEditor";

const UpdateBlog = () => {
    const navigate = useNavigate();
    const { blogId } = useParams();
    const [blogData, setBlogData] = useState({
        content: "",
        name: "",
        keywords: "",
        metaDescription: "",
        pageTitle: "",
    });
    const [isSaving, setIsSaving] = useState(false);
    const [blogImage, setBlogImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);

    useEffect(() => {
        const fetchBlogDetails = async () => {
            const response = await get_request(navigate, `/api/v1/admin/blogs/${blogId}`);
            setBlogData(response.data);
            setBlogImage(response.data.coverImage); // Assuming 'coverImage' contains the image info.
        };
        fetchBlogDetails();
    }, [blogId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBlogData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleFileChange = async (file) => {
        const formData = new FormData();
        formData.append("file", file);

        await put_request(
            navigate,
            `/api/v1/admin/assets/${blogImage?.id}`, // Assuming 'coverImage' has an 'id'.
            formData,
            {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
            }
        );
        setBlogImage(file);
        setPreviewUrl(null); // Reset preview URL after successful upload.
    };

    const handleSave = async (event) => {
        event.preventDefault();
        setIsSaving(true);

        await put_request(
            navigate,
            `/api/v1/admin/blogs/${blogId}`,
            {
                ...blogData,
                // Optionally include the cover image URL if needed.
            }
        );

        setIsSaving(false);
    };

    const handleDelete = async () => {
        await delete_request(navigate, `/api/v1/admin/blogs/${blogId}`);
        navigate("/BlogList");
    };

    return (
        <>
            <TopNav />
            <div id="layoutSidenav">
                <SideNav />
                <div
                    id="layoutSidenav_content"
                    style={{
                        padding: 50,
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "90%",
                        margin: "auto",
                        height: 400,
                        overflowY: "scroll",
                        alignItems: "center",
                        justifyContent: "start",
                    }}
                >
                    <div className="container">
                        <h2 style={{ textAlign: "center" }}>Blog Güncelle</h2>
                        {/* Image uploader */}
                        <div className="mb-4 mt-4 d-flex justify-content-center">
                            <FileUploader
                                file={blogImage?.url ? null : blogImage}
                                defaultUrl={blogImage?.url ? blogImage.url : null}
                                fileType="IMAGE"
                                onFileChange={handleFileChange}
                                onFileRemove={() => {
                                    setBlogImage(null);
                                    setPreviewUrl(null);
                                }}
                                isRemovable={false}
                            />
                        </div>

                        <form onSubmit={handleSave}>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Blog Adı</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    value={blogData.name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="content" className="form-label">Blog İçeriği</label>
                                <BlogEditor
                                    value={blogData.content}
                                    onChange={(text) => setBlogData((prevData) => ({...prevData, content: text}))}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="keywords" className="form-label">Anahtar Kelimeler - Meta Keywords</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="keywords"
                                    name="keywords"
                                    value={blogData.keywords}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="metaDescription" className="form-label">Meta Açıklama - Meta Description</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="metaDescription"
                                    name="metaDescription"
                                    value={blogData.metaDescription}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="pageTitle" className="form-label">Sayfa Başlığı - Page Titles</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="pageTitle"
                                    name="pageTitle"
                                    value={blogData.pageTitle}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="d-flex justify-content-center mt-4">
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={isSaving}
                                    style={{backgroundColor: isSaving ? "orange" : "#28a745"}}
                                >
                                    {isSaving ? "kaydediliyor..." : "güncelle"}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-danger ms-2"
                                    onClick={handleDelete}
                                >
                                    sil
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpdateBlog;
