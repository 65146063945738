import React, { useEffect, useRef, useState } from "react";
import classes from "./DraftAuctions.module.scss";
import SideNav from "../UI/navbars/SideNav";
import TopNav from "../UI/navbars/TopNav";
import { get_request } from "../utils/ApiHandlers";
import { useNavigate } from "react-router-dom";

const DraftAuctions = () => {
  const navigate = useNavigate();
  const [auctions, setAuctions] = useState(null);
  useEffect(() => {
    const fetchDraftAuctions = async () => {
      const response = await get_request(navigate,`/api/v1/admin/auctions/drafts`);
      setAuctions(response.data);
      return response;
    };
    fetchDraftAuctions();
  }, []);

  return (
    <>
      <TopNav />
      <div id="layoutSidenav">
        <SideNav />
        <div
          id="layoutSidenav_content"
          style={{
            padding: 50,
            display: "flex",
            flexDirection: "column",
            maxWidth: "90%",
            margin: "auto",
            height: 400,
            overflowY: "scroll",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          {/*bunun altına yapıştır */}
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">NAME</th>
              </tr>
            </thead>
            <tbody>
              {auctions &&
                auctions.map((auction) => {
                  return (
                    <>
                      <td>{auction.id}</td>
                      <td>{auction.name}</td>
                      <td
                        onClick={() =>
                          navigate(`/AuctionProducts/${auction.id}`)
                        }
                      >
                        <i className="fas fa-edit"></i>
                      </td>
                    </>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default DraftAuctions;
