import React, { useEffect, useState } from "react";
import classes from "./AuctionProducts.module.scss";
import SideNav from "../UI/navbars/SideNav";
import TopNav from "../UI/navbars/TopNav";
import { get_request } from "../utils/ApiHandlers";
import { useNavigate } from "react-router-dom";
import ProductCard from "../components/ProductCard";

const Products = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize] = useState(12); // Default size per page
    const [hasMoreData, setHasMoreData] = useState(false);

    useEffect(() => {
        fetchProducts(currentPage);
    }, []);

    const fetchProducts = async (page) => {
        const response = await get_request(
            navigate,
            `/api/v1/admin/products?page=${page}&size=${pageSize}`
        );
        console.log(response.data.content)
        if (response.data) {
            setProducts((prevProducts) => [...prevProducts, ...response.data.content]);

            setHasMoreData(response.data.content.length === pageSize);
        } else {
            setHasMoreData(false);
        }
    };

    const handleLoadMore = () => {
        const nextPage = currentPage + 1;
        if (hasMoreData) {
            fetchProducts(nextPage);
            setCurrentPage(nextPage);
        }
    };

    return (
        <>
            <TopNav />
            <div id="layoutSidenav">
                <SideNav />
                <div
                    id="layoutSidenav_content"
                    style={{
                        padding: 20,
                        maxWidth: "90%",
                        margin: "auto",
                        height: 400,
                        overflowY: "scroll",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                    }}
                >
                    {products.length > 0 && (
                        <div className={classes.auction_product_container}>
                            {products.map((product) => (
                                <ProductCard
                                    key={product.id}
                                    coverImage={product.coverImage}
                                    id={product.id}
                                    artistName={product.artistName}
                                    name={product.name}
                                    price={product.price}
                                    category={product.category}
                                    state={product.state}
                                />
                            ))}
                        </div>
                    )}
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <button class="btn btn-info"
                                style={{
                                    display: `${hasMoreData ? "block" : "none"}`,
                                    marginTop: "20px",
                                    width: "200px"
                                }}
                                onClick={handleLoadMore}
                        >
                            daha fazla yükle
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Products;
