import React from "react";
import classes from "./AuctionCard.module.scss";
import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { uiActions } from "../../store/uiSlice";

const AuctionCard = (props) => {
    //const dispatch = useDispatch();
    const navigate = useNavigate();
    /*const changePage = (page) => {
        dispatch(uiActions.changePage(page));
    };*/
    return (
        <div className={classes.current_auction}>
            <div className={classes.image_container}>
                <img className={classes.img} src={props.img.url}  alt={props.img.alt}/>
            </div>
            <div className={classes.auction_info_container}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <span className={classes.auction_name}>{props.name}</span>
                    <span className={classes.info_text} style={{ fontSize: 20 }}>
            müzayede no: {props.muzayede_no}
          </span>
                    {/* <span className={classes.info_text} style={{ fontSize: 18 }}>
            ONLINE
          </span> */}
                </div>
            </div>
            <div className={classes.auction_info_container}>
                <div style={{ display: "flex", flexDirection: "column" }}>
          <span className={classes.info_text} style={{ fontSize: 18 }}>
            {props.start_date}
          </span>
                    <span className={classes.info_text} style={{ fontSize: 18 }}>
            {props.end_date}
          </span>
                </div>
            </div>
            <div className={classes.btn_container}>
                <button
                    className={classes.current_auction__btn}
                    onClick={() => {
                        //changePage("auction");
                        navigate(`/finishedProducts/${props.id}`);
                    }}
                >
                    müzayede sonuçları
                </button>
            </div>
        </div>
    );
};

export default AuctionCard;