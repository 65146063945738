import React, { useEffect, useState } from "react";
import classes from "./AuctionProductCard.module.scss";
import { useNavigate } from "react-router-dom";
import {formatPrice} from "../utils/Formatters";

const ProductCard = (props) => {
    const navigate = useNavigate();

    return (
        <div className={classes.product_card} style={{height: "50vh"}}>
            <img
                src={props.coverImage && props.coverImage.url}
                className={classes.product_card__img}
            />
            <span className={classes.text_bold}>{props.name}</span>
            <span className={classes.text_bold}>{props.artistName}</span>
            <span className={classes.text_normal}>{props.category}</span>
            <span className={classes.text_bold}>{props.state}</span>
            <span
                style={{
                    fontFamily: "Gilroy-SemiBold",
                    marginBottom: "2vh",
                    fontSize: 18,
                    letterSpacing: 1.5,
                }}
            >
        {formatPrice(props.price.toString())} TL
      </span>
            <div className={classes.product_card__btn_container}>
                <div
                    onClick={() => {
                        navigate(`/UpdateProduct/${props.id}`);
                    }}
                    className={classes.banner}
                >
                    DÜZENLE
                </div>
            </div>
        </div>
    );
};

export default ProductCard;

