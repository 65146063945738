import React, { useState } from "react";
import classes from "./FinishedLotCard.module.scss";
import { useNavigate } from "react-router-dom";
import {get_request, post_request} from "../../utils/ApiHandlers";
import toastr from "toastr";
import { formatPrice } from "../../utils/Formatters";
import Modal from "../Modal";
import CardModal from "../model/CardModal"; // Import the Modal component

const FinishedLotCard = (props) => {
    const navigate = useNavigate();
    const [lot, setLot] = useState(props.lot);
    const [isSaving, setIsSaving] = useState(false);
    const [modalAction, setModalAction] = useState(null); // Store the action to perform
    const [showModal, setShowModal] = useState(false); // Manage modal visibility
    const [price, setPrice] = useState(0); // New state to store price for transfer

    async function handleActionConfirm() {
        setIsSaving(true);
        let response;
        if (modalAction === "approve") {
            response = await post_request(
                navigate,
                `/api/v1/admin/lots/${lot.lot.id}/confirm`,
                {}
            );
        } else if (modalAction === "cancel") {
            response = await post_request(
                navigate,
                `/api/v1/admin/lots/${lot.lot.id}/cancel`,
                {}
            );
        } else if (modalAction === "transfer") {
            response = await post_request(
                navigate,
                `/api/v1/admin/lots/${lot.lot.id}/transfer`,
                {}
            );
        }

        if (response && response.status === 200) {
            setLot(response.data);
            toastr.success("İşlem başarılı");
        }
        setIsSaving(false);
        setShowModal(false);
    }

    async function handleTransfer() {
        setIsSaving(true);

        try {
            const response = await get_request(navigate, `/api/v1/admin/lots/${lot.lot.id}/transfer`);

            if (response.status === 200 && response.data.price !== null) {
                setPrice(response.data.price);
                setModalAction("transfer");
                setShowModal(true);
            } else {
                toastr.error("Transfer için fiyat alınamadı.");
            }
        } catch (error) {
            toastr.error("Bir hata oluştu");
        }

        setIsSaving(false);
    }

    function openModal(action) {
        setModalAction(action);
        setShowModal(true);
    }

    return (
        lot && (
            <div className={classes.product_card} style={{ height: "70vh" }}>
                <img
                    src={lot.lot.coverImage && lot.lot.coverImage.url}
                    className={classes.product_card__img}
                    alt={lot.lot.coverImage.alt}
                />
                <span className={classes.text_bold}>
                    {lot.lot.name} ( lot: {lot.lot.lotNumber} )
                </span>
                <span className={classes.text_bold}>{lot.artistName}</span>
                <span
                    style={{
                        fontFamily: "Gilroy-Regular",
                        marginBottom: "5px",
                        fontSize: 15,
                        letterSpacing: 0.5,
                    }}
                >
          başlangıç fiyatı: {formatPrice(lot.lot.initialPrice.toString())} TL
        </span>
                {lot.lot.finalPrice && lot.lot.state !== "NOT_SOLD" && (
                    <>
            <span
                style={{
                    fontFamily: "Gilroy-Regular",
                    marginBottom: "5px",
                    fontSize: 15,
                    letterSpacing: 0.5,
                }}
            >
              kullanıcı teklifi: {formatPrice(lot.lot.finalPrice.toString())} TL
            </span>
                        <span
                            style={{
                                fontFamily: "Gilroy-Regular",
                                marginBottom: "5px",
                                fontSize: 15,
                                letterSpacing: 0.5,
                            }}
                        >
              kdv dahil: {formatPrice((lot.lot.finalPrice * 1.2).toString())} TL
            </span>
                    </>
                )}
                {lot.lot.state === "SOLD" ? (
                    <>
                        <span className={classes.text_bold}>kazanan:</span>
                        <div className={classes.overflow}>
              <span className={classes.text_without_margin}>
                {lot.buyer && `${lot.buyer.name} ${lot.buyer.surname}`}
              </span>
                            <span className={classes.text_without_margin}>
                {lot.buyer && lot.buyer.email}
              </span>
                            <span className={classes.text_without_margin}>
                {lot.buyer && lot.buyer.phone}
              </span>
                            <span className={classes.text_without_margin}>
                {lot.buyer && lot.buyer.address}
              </span>
                        </div>
                        <div className={classes.product_card__btn_container}>
                            <div
                                onClick={() => openModal("approve")}
                                className={classes.product_card__btn_container__btn}
                            >
                                onayla
                            </div>
                            {lot.isTransferEnabled && (
                                <div
                                    onClick={handleTransfer}
                                    className={classes.product_card__btn_container__btn}
                                    style={{ marginLeft: "5px", marginRight: "5px" }}
                                >
                                    transfer
                                </div>
                            )}
                            <div
                                onClick={() => openModal("cancel")}
                                className={classes.product_card__btn_container__btn}
                            >
                                reddet
                            </div>
                        </div>
                    </>
                ) : (
                    <>

                            {lot.lot.state === "APPROVED" ? (
                                <>

                                    <span className={classes.text_bold}>kazanan:</span>
                                    <div className={classes.overflow}>
                    <span className={classes.text_without_margin}>
                      {lot.buyer && `${lot.buyer.name} ${lot.buyer.surname}`}
                    </span>
                                        <span className={classes.text_without_margin}>
                      {lot.buyer && lot.buyer.email}
                    </span>
                                        <span className={classes.text_without_margin}>
                      {lot.buyer && lot.buyer.phone}
                    </span>
                                        <span className={classes.text_without_margin}>
                      {lot.buyer && lot.buyer.address}
                    </span>
                                    </div>
                                    <div className={classes.banner}>
                                        onaylandı
                                    </div>
                                </>
                            ) : (
                                <div className={classes.banner}>satılmadı</div>
                            )}

                    </>
                )}

                <CardModal
                    show={showModal}
                    title="Onay Ekranı"
                    onClose={() => setShowModal(false)}
                    onSave={handleActionConfirm}
                >
                    {modalAction === "transfer" ? (
                        <p>Sıradaki alıcının teklifi: <b>{formatPrice(price.toString())}</b> TL. Transferi onaylıyor musunuz?</p>
                    ) : (
                        <p>
                            {modalAction === "approve" ? "Alıcıya satışı onaylıyor musunuz?" : "Eser satılmayarak rafa kaldırılacak onaylıyor musunuz?"}
                        </p>
                    )}
                </CardModal>
            </div>
        )
    );
};

export default FinishedLotCard;
