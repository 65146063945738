import React, { useEffect, useRef, useState } from "react";
import classes from "./UpdateAuctionProduct.module.scss";
import SideNav from "../UI/navbars/SideNav";
import TopNav from "../UI/navbars/TopNav";
import "react-image-upload/dist/index.css";
import {
  delete_request,
  get_request,
  post_request,
  put_request,
} from "../utils/ApiHandlers";
import {useNavigate, useParams} from "react-router-dom";
import "rc-select/assets/index.css";
import SelectDropdown from "../components/SelectDropdown";
import FileUploader from "../components/FileUploader";

class Artist {
  constructor(label, id) {
    this.label = label;
    this.id = id;
  }
}

const UpdateAuctionProduct = () => {
  const navigate = useNavigate();
  const { auctionProductId } = useParams();
  const [artistOptions, setArtistOptions] = useState(null);
  const [selectedArtistId, setSelectedArtistId] = useState(null);
  const [files, setFiles] = useState(null);
  const [auctionId, setAuctionId] = useState(null);
  const [coverImageFile, setCoverImageFile] = useState(null);
  const auctionProductNameRef = useRef();
  const auctionProductDescriptionNameRef = useRef();
  const initialPriceRef = useRef();

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const fetchArtists = async () => {
      const response = await get_request(navigate, `/api/v1/admin/artists/name`);
      const artistsResponse = response.data;
      setArtistOptions(
        artistsResponse.map((artist) => {
          return new Artist(artist.fullName, artist.id);
        })
      );
    };
    fetchArtists();
  }, []);

  const onSetSelectedArtist = (id) => {
    setSelectedArtistId(id);
  };


  useEffect(() => {
    const fetchDraftAuctionProduct = async () => {
      const response = await get_request(
          navigate,
        `/api/v1/admin/lots/${auctionProductId}`
      );
      const draftAuctionProduct = response.data;

      auctionProductNameRef.current.value = draftAuctionProduct.name;
      auctionProductDescriptionNameRef.current.value = draftAuctionProduct.description;
      initialPriceRef.current.value = draftAuctionProduct.initialPrice;
      setCoverImageFile(draftAuctionProduct.coverImage);
      setFiles(
          draftAuctionProduct?.mediaAssets?.length > 0 ? [...draftAuctionProduct.mediaAssets, null] : [null]
      );
      setAuctionId(draftAuctionProduct.auctionId);
      setSelectedArtistId(draftAuctionProduct.artistId);
      return response;
    };
    fetchDraftAuctionProduct();
  }, [auctionProductId]);

  const handleFileChange = async (file, index) => {
    let response;
    if (files[index]?.id) {
      const formData = new FormData();
      formData.append("file", file);
      response = await put_request(
          navigate,
          `/api/v1/admin/assets/${files[index].id}`,
          formData,
          {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          }
      );
      const newFiles = [...files];
      newFiles[index] = response.data;

      if (index === files.length - 1) {
        newFiles.push(null);
      }

      setFiles(newFiles);
    } else {
      const formData = new FormData();
      formData.append("file", file);
      response = await post_request(
          navigate,
          `/api/v1/admin/assets/${auctionProductId}`,
          formData,
          {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          }
      );
      const newFiles = [...files];
      newFiles[index] = response.data;

      if (index === files.length - 1) {
        newFiles.push(null);
      }

      setFiles(newFiles);
    }
  };

  const handleFileRemove = async (index, imageId) => {
    console.log(index, imageId);
    const response = await delete_request(
        navigate,
        `/api/v1/admin/assets/${imageId}`
    );
    if (response.status === 200) {
      const newFiles = files.filter((_, i) => i !== index);
      if (newFiles.length === 0) {
        newFiles.push(null);
      }

      setFiles(newFiles);
    }
  };

  const handleCoverImageChange = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const response = await put_request(
        navigate,
        `/api/v1/admin/assets/${coverImageFile?.id}`,
        formData,
        {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        }
    );
    console.log(response.data);
    setCoverImageFile(response.data);
  };

  const handleCoverImageRemove = async (file) => {
    const response = await delete_request(
        navigate,
        `/api/v1/admin/assets/${coverImageFile?.id}`
    );
    if (response.status === 200) {
      setCoverImageFile(null);
    }
  };

  async function onSaveHandler(event) {
    setIsSaving(true)
    event.preventDefault();
    const response = await put_request(
        navigate,
      `/api/v1/admin/lots/${auctionProductId}`,
      {
        name: auctionProductNameRef.current.value,
        description: auctionProductDescriptionNameRef.current.value,
        initialPrice: initialPriceRef.current.value,
        artistId: selectedArtistId,
      },
      {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      }
    );
    setIsSaving(false)
  }

  async function onDeleteHandler(event) {
    event.preventDefault();
    const response = await delete_request(
        navigate,
        `/api/v1/admin/lots/${auctionProductId}`
    );
    if (response.status === 200) {
      navigate(`/AuctionProducts/${auctionId}`);
    }
  }

  return (
    <>
      <TopNav />
      <div id="layoutSidenav">
        <SideNav />
        <div
            id="layoutSidenav_content"
            style={{
              padding: 50,
              display: "flex",
              flexDirection: "column",
              maxWidth: "90%",
              margin: "auto",
              height: 400,
              overflowY: "scroll",
              alignItems: "center",
              justifyContent: "start",
            }}
        >
          {/*bunun altına yapıştır */}
          <div className="input-group mb-3 d-flex align-center justify-content-center">
            <FileUploader
                file={coverImageFile?.url ? null : coverImageFile}
                defaultUrl={coverImageFile?.url ? coverImageFile.url : null}
                fileType="IMAGE"
                onFileChange={handleCoverImageChange}
                onFileRemove={handleCoverImageRemove}
            />
          </div>

          <div className="input-group mb-3 ">
            <input
                type="text"
                className="form-control mx-2"
                id="basic-url"
                placeholder="Eser Adı"
                aria-describedby="basic-addon3"
                ref={auctionProductNameRef}
            />
            <input
                type="text"
                className="form-control mx-2"
                id="basic-url2"
                placeholder="Açıklama"
                aria-describedby="basic-addon3"
                ref={auctionProductDescriptionNameRef}
            />
          </div>
          <div className="input-group mb-3 ">
            <input
                type="text"
                className="form-control mx-2"
                id="basic-url2"
                placeholder="Başlangıç Fiyatı"
                aria-describedby="basic-addon3"
                ref={initialPriceRef}
            />
            <SelectDropdown
                selectedOption={selectedArtistId}
                placeholder="Sanatçı seçiniz"
                width={"49%"}
                margin={"0 6px 0 6px"}
                options={artistOptions}
                onSetSelectedArtist={onSetSelectedArtist}
            />
          </div>

          <div className={classes.image_grid_container}>
            {files &&
                files.map((file, index) => {
                  return (
                      <FileUploader
                          key={index}
                          file={file?.url ? null : file}
                          index={index}
                          fileType={
                            file &&
                            (file.type.includes("image") || file.type.includes("IMAGE"))
                                ? "IMAGE"
                                : "VIDEO"
                          }
                          defaultUrl={file?.url}
                          onFileChange={handleFileChange}
                          onFileRemove={handleFileRemove.bind(
                              this,
                              index,
                              file?.id ?? null
                          )}
                      />
                  );
                })}
          </div>
          <div className="d-flex flex-row">
            <button
                onClick={onSaveHandler}
                className="mt-2 mx-2 btn btn-success"
                style={{ backgroundColor: isSaving ? "orange" : "#28a745", width: "200px"}}
                disabled={isSaving}
            >
              {isSaving ? "kaydediliyor..." : "kaydet"}
            </button>
            <button
                onClick={onDeleteHandler}
                className="mt-2 mx-2 btn btn-danger"
                style={{width: 200}}
            >
              sil
            </button>
          </div>
          <div
              className="mt-4 p-3 bg-warning text-dark"
              style={{borderRadius: "5px", maxWidth: "90%", margin: "auto"}}
          >
            <h5>Dikkat Edilmesi Gerekenler:</h5>
            <ul style={{paddingLeft: "20px"}}>
              <li>
                <b>Kapak Fotoğrafı</b> silinemez sadece değiştirilebilir.
              </li>
              <li>
                <b>Ürün İsim</b> ve <b>Ürün Detay</b> <b>Başlangıç Fiyatı</b> ve <b>Sanatçı</b> alanlarını
                değiştirdikten sonra kayıt edebilebilmesi için <b>Güncelle</b> butonuna tıklanılması gerekmektedir.
              </li>
              <li>
                Görseller her değişiklikte otomatik olarak güncellenir. Silme işlemini 2
                kere düşünün derim :))
              </li>
              <li>
                İstenilen kadar <b>Ürün Görseli</b> ya da video ekleyebilirsiniz ancak fazla görselin siteyi yavaşlatma
                potansiyeli vardır.
              </li>
              <li>
                Görsellerin boyutları en fazla <b>300 kb</b> olması site sağlığı için önemlidir.
                Olabildiğince az video yüklenmesi tercih edilmelidir.
              </li>
              <li>
                Yüklenen görsellerin isimleri belli bir düzen içinde olması SEO için
                önemlidir. Örneğin:
                <ul>
                  <li>kapak-Ürünİsim-Müzayedeİsim-SanatçıSoyisim</li>
                  <li>görsel-Ürünİsim-Müzayedeİsim-SanatçıSoyisim-1</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateAuctionProduct;
