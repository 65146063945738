import React, { useEffect, useState } from "react";
import classes from "./AuctionProducts.module.scss";
import SideNav from "../UI/navbars/SideNav";
import AuctionProductCard from "../components/AuctionProductCard";
import TopNav from "../UI/navbars/TopNav";
import { get_request } from "../utils/ApiHandlers";
import { useNavigate, useParams } from "react-router-dom";

const AuctionProducts = () => {
    const navigate = useNavigate();
    const { auctionId } = useParams();
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize] = useState(20); // Set the desired page size
    const [hasMoreData, setHasMoreData] = useState(false);

    useEffect(() => {
        fetchProducts(currentPage);
    }, []);

    const fetchProducts = async (page) => {
        const response = await get_request(
            navigate,
            `/api/v1/admin/auctions/${auctionId}/lots?page=${page}&size=${pageSize}`
        );
        if (response.data) {
            setProducts((prevProducts) => [
                ...prevProducts,
                ...response.data.content,
            ]);

            setHasMoreData(response.data.content.length === pageSize);
        } else {
            setHasMoreData(false);
        }
    };

    const handleLoadMore = () => {
        const nextPage = currentPage + 1;
        if (hasMoreData) {
            fetchProducts(nextPage);
            setCurrentPage(nextPage);
        }
    };

    return (
        <>
            <TopNav />
            <div id="layoutSidenav">
                <SideNav />
                <div
                    id="layoutSidenav_content"
                    style={{
                        padding: 20,
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "90%",
                        margin: "auto",
                        height: 400,
                        overflowY: "scroll",
                        justifyContent: "start",
                    }}
                >
                    <button
                        onClick={() => {
                            navigate(`/CreateAuctionProduct/${auctionId}`);
                        }}
                        className="btn btn-primary"
                    >
                        Eser Ekle
                    </button>
                    {products && (
                        <div className={classes.auction_product_container}>
                            {products.map((product) => (
                                <AuctionProductCard
                                    coverImage={product.coverImage}
                                    key={product.id}
                                    id={product.id}
                                    artistFullName={product.artistFullName}
                                    name={product.name}
                                    currentPrice={product.initialPrice}
                                    description={product.description}
                                />
                            ))}
                        </div>
                    )}
                    {hasMoreData && (
                        <button
                            onClick={handleLoadMore}
                            className="btn btn-secondary w-25 mx-auto my-3"
                        >
                            Daha Fazla Yükle
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};

export default AuctionProducts;
