import React from "react";
import classes from "./ArtistCard.module.scss";
import { useNavigate } from "react-router-dom";
const ArtistCard = (props) => {
  let name = props.fullName.split(" ")[0];
  let surname = props.fullName.split(" ")[1];

  const navigate = useNavigate();
  return (
    <div
      className={classes.artist_card}
      onClick={() => {
        navigate(`/UpdateArtist/${props.id}`);
      }}
    >
      <img className={classes.artist_card__img} src={props.imageUrl} />

      <span className={classes.artist_card_name}>
        {name} <br />
        {surname}
      </span>
    </div>
  );
};

export default ArtistCard;
