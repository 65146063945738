import React, { useEffect, useRef, useState } from "react";
import toastr from "toastr";
import SideNav from "../UI/navbars/SideNav";
import TopNav from "../UI/navbars/TopNav";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import {
  delete_request,
  get_request,
  patch_request,
  put_request,
} from "../utils/ApiHandlers";
import { useNavigate, useParams } from "react-router-dom";
import FileUploader from "../components/FileUploader";
import Modal from "../components/Modal";
const UpdateAuction = () => {
  const navigate = useNavigate();
  const [auctionImage, setAuctionImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const { auctionId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [state, setState] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  const auctionNameRef = useRef();
  const auctionDescriptionName = useRef();

  const handleTime = (time) => {
    return (new Date(time - time.getTimezoneOffset()*60*1000)).toISOString();
  };

  useEffect(() => {
    const fetchDraftAuction = async () => {
      const response = await get_request(
        navigate,
        `/api/v1/admin/auctions/${auctionId}`
      );
      const draftAuction = response.data;
      console.log(draftAuction);

      console.log(draftAuction.startTime);
      setStartTime(new Date(draftAuction.startTime));
      setEndTime(new Date(draftAuction.endTime));
      auctionNameRef.current.value = draftAuction.name;
      auctionDescriptionName.current.value = draftAuction.description;
      setAuctionImage(draftAuction.coverAsset);
      setState(draftAuction.state);
      return response;
    };
    fetchDraftAuction();
  }, [auctionId]);

  const onReadyHandler = async () => {
    setIsSaving(true)
    const response = await patch_request(
      navigate,
      `/api/v1/admin/auctions/${auctionId}/set-ready`,
      {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      }
    );
    if (response.status === 200) {
      setState("READY");
    }
    setIsSaving(false)
  };
  const onDraftHandler = async () => {
    setIsSaving(true)
    const response = await patch_request(
      navigate,
      `/api/v1/admin/auctions/${auctionId}/set-draft`,
      {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      }
    );
    if (response.status === 200) {
        setState("DRAFT");
    }
    setIsSaving(false)
  };

  const handleFileChange = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    await put_request(
      navigate,
      `/api/v1/admin/assets/${auctionImage.id}`,
      formData,
      {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      }
    );
    setAuctionImage(file);
    setPreviewUrl(null);
  };

  async function onSaveHandler(event) {
    if (state === "READY") {
      toastr.error("Müzayede taslak olmadan güncellenemez")
      return
    }
    setIsSaving(true);
    event.preventDefault();

    await put_request(
      navigate,
      `/api/v1/admin/auctions/${auctionId}`,
      {
        name: auctionNameRef.current.value,
        description: auctionDescriptionName.current.value,
        startTime: handleTime(startTime),
        endTime: handleTime(endTime),
      },
      {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      }
    );
    setIsSaving(false)
  }

  const onDeleteAuctionHandler = async () => {
    if (state === "READY") {
        toastr.error("Müzayede taslak olmadan silinemez")
        return
    }
    const response = await delete_request(
      navigate,
      `/api/v1/admin/auctions/${auctionId}`
    );
    if (response.status === 200) {
      navigate("/Dashboard");
    }
  };

  const onCloseModalHandler = () => {
    setShowModal(false);
  };
  return (
    <>
      <TopNav />
      <div id="layoutSidenav">
        <SideNav />
        <div
            id="layoutSidenav_content"
            style={{
              padding: 50,
              display: "flex",
              flexDirection: "column",
              maxWidth: "90%",
              margin: "auto",
              height: 400,
              overflowY: "scroll",
              alignItems: "center",
              justifyContent: "start",
            }}
        >
          {/*bunun altına yapıştır */}
          <Modal onClose={onCloseModalHandler} show={showModal}>
            <span>Bu resmi silmek istediğinize emin misiniz?</span>
          </Modal>
          <div className="mb-4">
            <FileUploader
                file={auctionImage?.url ? null : auctionImage}
                defaultUrl={auctionImage?.url ? auctionImage.url : null}
                fileType="IMAGE"
                onFileChange={handleFileChange}
                onFileRemove={() => {
                }}
                isRemovable={false}
            />
          </div>
          <div className="input-group mb-3 ">
            <input
                type="text"
                className="form-control mx-2"
                id="basic-url"
                placeholder="Müzayede Adı"
                aria-describedby="basic-addon3"
                ref={auctionNameRef}
            />
            <input
                type="text"
                className="form-control mx-2"
                id="basic-url2"
                placeholder="Açıklama"
                aria-describedby="basic-addon3"
                ref={auctionDescriptionName}
            />
          </div>
          <div className="input-group mb-3 d-flex flex-row justify-content-center">
            <div className="mx-2 d-flex flex-row form-control bg-gradient ">
              <label htmlFor="basic-url" className="form-label mx-3">
                Başlangıç Tarihi
              </label>
              <div className="mb-4">
                <DateTimePicker onChange={setStartTime} value={startTime}/>
              </div>
            </div>
            <div className="mx-2 d-flex flex-row form-control">
              <label htmlFor="basic-url" className="form-label mx-3">
                Bitiş Tarihi
              </label>
              <div>
                <DateTimePicker onChange={setEndTime} value={endTime}/>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row">
            <button
                onClick={() => {
                  navigate(`/AuctionProducts/${auctionId}`);
                }}
                className="mt-4 mx-2 btn btn-secondary"
                style={{width: 200}}
            >
              eserleri düzenle
            </button>
            <button
                onClick={onSaveHandler}
                className="mt-4 mx-2 btn btn-success"
                style={{ backgroundColor: isSaving ? "orange" : "#28a745", width: "200px"}}
                disabled={isSaving}
            >
              {isSaving ? "kaydediliyor..." : "kaydet"}
            </button>
            <button
                onClick={onDeleteAuctionHandler}
                className="mt-4 mx-2 btn btn-danger"
                style={{width: 200}}
            >
              SİL
            </button>
          </div>
          <div>
            {state === "DRAFT" && (
                <button
                    onClick={onReadyHandler}
                    className="mt-4 mx-2 btn btn-warning"
                    style={{ backgroundColor: isSaving ? "orange" : "#28a745", width: "200px"}}
                    disabled={isSaving}
                >
                  {isSaving ? "kaydediliyor..." : "müzayede hazır"}
                </button>
            )}
            {state === "READY" && (
                <button
                    onClick={onDraftHandler}
                    className="mt-4 mx-2 btn btn-warning"
                    style={{ backgroundColor: isSaving ? "orange" : "#28a745", width: "200px"}}
                    disabled={isSaving}
                >
                  {isSaving ? "kaydediliyor..." : "müzayede taslak"}
                </button>
            )}
          </div>
          <div
              className="mt-4 p-3 bg-warning text-dark"
              style={{borderRadius: "5px", maxWidth: "90%", margin: "auto"}}
          >
            <h5>Dikkat Edilmesi Gerekenler:</h5>
            <ul style={{paddingLeft: "20px"}}>
              <li>Müzayede kapak fotoğrafı silinemez, sadece değiştirilebilir.</li>
              <li>
                <b>Müzayede İsim</b>, <b>Müzayede Detay</b>, <b>Başlangıç Tarihi</b> ve <b>Bitiş
                Tarihi</b> kısımları <b>Güncelle</b> butonu ile güncellenmektedir.
                Değişikliklerden sonra <b>Güncelle</b> butonuna basılması gerekmektedir.
              </li>
              <li>
                Görsellerin boyutları en fazla <b>300 kb</b> olması site sağlığı için önemlidir.
              </li>
              <li>
                <b>Eserleri Düzenle</b> butonu ile müzayede eserleri düzenlenebilir.
              </li>
              <li>
                <b>Müzayede Hazır</b> butonu müzayedenin başlamasını sağlar. Hazırda bekleyen müzayede düzenlenemez.
                Düzenleyebilmek için <b>Müzayede Taslak</b> butonuna basılarak müzayedenin taslak konumuna getirilmesi
                gerekmektedir.
              </li>
              <li>
                Birden fazla Müzayede taslak oluşturabilirsiniz. Birden fazla Müzayedeyi başlamaya hazır hale
                getirebilirsiniz. Ancak tarihlere dikkat ediniz, aynı anda birden fazla müzayede başlayamaz.
              </li>
              <li>
              Yüklenen görsellerin isimleri belli bir düzen içinde olması SEO için
              önemlidir. Örneğin:
              <ul>
                <li>kapak-Müzayedeİsim</li>
              </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateAuction;
