import React, { useState, useEffect } from 'react';
import MDEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import { ChromePicker } from 'react-color';
import { marked } from 'marked';
import "./MarkdownEditor.scss"

// marked ayarları
marked.setOptions({
    breaks: true,
    gfm: true,
    sanitize: true
});

const MarkdownEditor = ({ value, onChange }) => {
    const [editorValue, setEditorValue] = useState(value);
    const [color, setColor] = useState('#000');

    useEffect(() => {
        setEditorValue(value);
    }, [value]);

    const applyColorToText = () => {
        const selection = window.getSelection();
        const selectedText = selection.toString();

        if (selectedText.length > 0) {
            // Markdown formatında renkli metin ekle
            const coloredText = `<span style="color: ${color};">${selectedText}</span>`;
            const updatedText = editorValue.replace(selectedText, coloredText);
            setEditorValue(updatedText);
            onChange(updatedText);
        }
    };

    const handleColorChange = (color) => {
        setColor(color.hex);
    };

    const handleEditorChange = ({ text }) => {
        setEditorValue(text);
        onChange(text);
    };

    const renderHTML = (text) => {
        return marked(text);
    };

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '10px' }}>
                <ChromePicker color={color} onChangeComplete={handleColorChange} />
                <button
                    onClick={applyColorToText}
                    style={{ marginTop: '10px', display: 'block', width: '100%' }}
                >
                    Renk Uygula
                </button>
            </div>
            <MDEditor
                value={editorValue}
                renderHTML={renderHTML}
                onChange={handleEditorChange}
                config={{
                    view: {
                        menu: true,
                        md: true,
                        html: true,
                    }
                }}
                style={{
                    resize: 'vertical',
                    width: '100%',
                    height: '300px',
                }}
            />
        </div>
    );
};

export default MarkdownEditor;
