import React, { useEffect, useState } from "react";
import classes from "./SelectDropdown.module.scss";

const SelectDropdown = (props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredOptions =
    props.options &&
    props.options.filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

  // console.log(props.options.find((obj) => obj.id === selectedOption).label);
  return (
    <div
      className={classes.root}
      style={{
        height: props.height && props.height,
        width: props.width && props.width,
        margin: props.margin && props.margin,
      }}
    >
      {props.selectedOption && (
        <div
          onClick={() => {
            props.onSetSelectedArtist(null);
          }}
        >
          <i
            style={{
              position: "absolute",
              top: "12px",
              right: "25px",
              cursor: "pointer",
              zIndex: 900,
            }}
            color="gray"
            className="fa-regular fa-circle-xmark"
          ></i>
        </div>
      )}
      <div
        onClick={() => setIsDropdownOpen((prev) => !prev)}
        className={classes.rootButton}
      >
        <span style={{ color: !props.selectedOption && "gray" }}>
          {props.selectedOption && props.options
            ? props.options.find((obj) => obj.id === props.selectedOption).label
            : props.placeholder}
        </span>
        <i className="fas fa-angle-down" style={{ color: "gray" }}></i>
      </div>
      {isDropdownOpen && (
        <div className={classes.dropdownMenu}>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={classes.searchInput}
            placeholder="Ara"
          />
          {filteredOptions &&
            filteredOptions.map((option) => (
              <div
                key={option.id}
                onClick={() => {
                  props.onSetSelectedArtist(option.id);
                  console.log("cc", option.id);
                  setIsDropdownOpen(false);
                }}
                className={classes.optionItem}
              >
                <span>{option.label}</span>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default SelectDropdown;
