import { useNavigate } from "react-router-dom";
import { post_request } from "../utils/ApiHandlers";
import { useState } from "react";
import toastr from "toastr";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
    try {
      const response = await post_request(navigate, "/api/v1/auth", {
        email: email,
        password: password,
      });
      if (response.data.admin) {
        localStorage.setItem("token", response.data.token);
        navigate("/Dashboard");
      }
      else {
        toastr.error("Bu sayfaya erişim yetkiniz bulunmamaktadır.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="bg-primary d-flex justify-content-center align-items-center vh-100">
      <div className="card shadow-lg border-0 rounded-lg">
        <div className="card-header">
          <h3 className="text-center font-weight-light my-4">
            UpArt Admin Panel
          </h3>
        </div>
        <div className="card-body">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleLogin();
            }}
          >
            <div className="form-floating mb-3">
              <input
                className="form-control"
                id="inputEmail"
                type="email"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="inputEmail">Email</label>
            </div>
            <div className="form-floating mb-3">
              <input
                className="form-control"
                id="inputPassword"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label htmlFor="inputPassword">Şifre</label>
            </div>
            <div className="d-flex justify-content-center">
              <button type="submit" className="btn btn-primary">
                Giriş Yap
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
