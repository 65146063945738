import React, { useState, useEffect } from "react";
import classes from "./FileUploader.module.scss";
import { v4 as uuidv4 } from "uuid";

const FileUploader = ({
  onFileChange,
  onFileRemove,
  file,
  index,
  defaultUrl = null,
  fileType = "IMAGE",
  isRemovable = true,
}) => {
  const [previewUrl, setPreviewUrl] = useState(null);
  const uniqueId = uuidv4();
  useEffect(() => {
    if (file !== null) {
      console.log("girdi");
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setPreviewUrl(fileReader.result);
      };
      fileReader.readAsDataURL(file);
    }
    if (file === null) {
      console.log("girdi");
      setPreviewUrl(defaultUrl);
    }
  }, [file, defaultUrl]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      onFileChange(selectedFile, index);
    }
  };

  const handleRemoveFile = (event) => {
    event.stopPropagation();
    onFileRemove(index);
  };

  return (
    <div
      className={classes.container}
      onClick={() => document.getElementById(uniqueId).click()}
    >
      <input
        id={uniqueId}
        type="file"
        accept="image/*,video/*"
        onChange={handleFileChange}
        className={classes.uploadInput}
      />
      {previewUrl ? (
        <div className={classes.previewContainer}>
          {isRemovable && (
            <button onClick={handleRemoveFile} className={classes.removeButton}>
              ✖
            </button>
          )}
          {fileType?.includes("IMAGE") ? (
            <img
              src={previewUrl}
              alt="Preview"
              className={classes.previewImage}
            />
          ) : (
            <video
              src={previewUrl}
              alt="Preview"
              className={classes.previewVideo}
              autoPlay={true}
            />
          )}
        </div>
      ) : (
        <div className={classes.uploadIcon}>➕</div>
      )}
    </div>
  );
};

export default FileUploader;
