import React, { useEffect, useRef, useState } from "react";
import classes from "./CreateAuctionProduct.module.scss";
import SideNav from "../UI/navbars/SideNav";
import TopNav from "../UI/navbars/TopNav";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-image-upload/dist/index.css";
import { get_request, post_request } from "../utils/ApiHandlers";
import {useNavigate, useParams} from "react-router-dom";
import SelectDropdown from "../components/SelectDropdown";
import FileUploader from "../components/FileUploader";
import MarkdownEditor from "../components/MarkdownEditor";

class Artist {
    constructor(label, id) {
        this.label = label;
        this.id = id;
    }
}

const CreateProduct = () => {
    const navigate = useNavigate();
    const [coverImageFile, setCoverImageFile] = useState(null);
    const [selectedArtistId, setSelectedArtistId] = useState(null);
    const [artistOptions, setArtistOptions] = useState();
    const [files, setFiles] = useState([null]);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        const fetchArtists = async () => {
            const response = await get_request(navigate, `/api/v1/admin/artists/name`);
            const artistsResponse = response.data;
            console.log(artistsResponse)
            setArtistOptions(
                artistsResponse.map((artist) => {
                    return new Artist(artist.fullName, artist.id);
                })
            );
            return response;
        };
        fetchArtists();
    }, []);

    const [description, setDescription] = useState("");
    const productNameRef = useRef();
    const priceRef = useRef();
    const categoryRef = useRef();

    const handleCoverImageChange = (file) => {
        setCoverImageFile(file);
    };

    const handleCoverImageRemove= () => {
        setCoverImageFile(null);
    };

    const handleFileChange = (file, index) => {
        const newFiles = [...files];
        newFiles[index] = file;

        if (index === files.length - 1) {
            newFiles.push(null);
        }

        setFiles(newFiles);
    };

    const handleFileRemove = (index) => {
        const newFiles = files.filter((_, i) => i !== index);
        if (newFiles.length === 0) {
            newFiles.push(null);
        }

        setFiles(newFiles);
    };



    const onSetSelectedArtist = (id) => {
        setSelectedArtistId(id);
    };

    async function onSaveHandler(event) {
        setIsSaving(true)
        event.preventDefault();
        const formData = new FormData();

        const productSaveRequest = {
            name: productNameRef.current.value,
            description: description,
            price: priceRef.current.value,
            category: categoryRef.current.value,
            artistId: selectedArtistId,
        };

        const productSaveRequestBlob = new Blob(
            [JSON.stringify(productSaveRequest)],
            {
                type: "application/json",
            }
        );

        formData.append("productSaveRequest", productSaveRequestBlob);
        formData.append("coverImage", coverImageFile);
        files.forEach((file, index) => {
            if (file) {
                formData.append(`assets`, file);
            }
        });

        const response = await post_request(navigate, "/api/v1/admin/products", formData, {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
        });

        setIsSaving(false)

        if (response.status === 200) {
            navigate(`/UpdateProduct/${response.data.id}`);
        }

    }

    return (
        <>
            <TopNav />
            <div id="layoutSidenav">
                <SideNav />
                <div
                    id="layoutSidenav_content"
                    style={{
                        padding: 50,
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "90%",
                        margin: "auto",
                        height: 400,
                        overflowY: "scroll",
                        alignItems: "center",
                        justifyContent: "start",
                    }}
                >
                    <div className="input-group mb-3 d-flex align-center justify-content-center">
                        <FileUploader
                            file={coverImageFile}
                            onFileChange={handleCoverImageChange}
                            onFileRemove={handleCoverImageRemove}
                        />
                    </div>
                    {/*bunun altına yapıştır */}
                    <div className="input-group mb-3 ">
                        <input
                            type="text"
                            className="form-control mx-2"
                            id="basic-url"
                            placeholder="Eser Adı"
                            aria-describedby="basic-addon3"
                            ref={productNameRef}
                        />
                        <input
                            type="text"
                            className="form-control mx-2"
                            id="basic-url2"
                            placeholder="Fiyat"
                            aria-describedby="basic-addon3"
                            ref={priceRef}
                        />
                    </div>
                    <div className="input-group mb-3 ">
                        <input
                            type="text"
                            className="form-control mx-2"
                            id="basic-url2"
                            placeholder="Kategori"
                            aria-describedby="basic-addon3"
                            ref={categoryRef}
                        />
                        <SelectDropdown
                            width="300px"
                            margin="0 6px"
                            selectedOption={selectedArtistId}
                            placeholder="Sanatçı seçiniz"
                            options={artistOptions}
                            onSetSelectedArtist={onSetSelectedArtist}
                        />
                    </div>
                        <h5>Eser Detay</h5>
                        <div className="container-fluid mb-3 ">
                            <MarkdownEditor
                                value={description}
                                onChange={(text) => setDescription(text)}
                            />
                        </div>

                    <div className={classes.artwork_container}>
                        {files.map((file, index) => (
                            <FileUploader
                                key={index}
                                file={file}
                                index={index}
                                fileType={file && file.type}
                                onFileChange={handleFileChange}
                                onFileRemove={handleFileRemove}
                            />
                        ))}
                    </div>
                    <button
                        onClick={onSaveHandler}
                        className="mt-2 btn btn-success"
                        style={{ backgroundColor: isSaving ? "orange" : "#28a745", width: "200px"}}
                        disabled={isSaving}
                    >
                        {isSaving ? "kaydediliyor..." : "satışa çıkar"}
                    </button>
                </div>
            </div>
        </>
    );
};

export default CreateProduct;
