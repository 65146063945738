import React from 'react';
import { Editor } from '@tinymce/tinymce-react';


const BlogEditor = ({ value, onChange }) => {
    const handleEditorChange = (content, editor) => {
        onChange(content);
    };

    return (
        <>
            <Editor
                apiKey='4q0hplfvo7n735jvywvcod8sbrlfm5xngluaod2ugshbk23z'
                init={{
                    height: 300,
                    menubar: false,
                    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount  linkchecker fullscreen',
                    toolbar: `
                    undo redo | blocks fontsize | align bold italic underline strikethrough forecolor backcolor | 
                    link image media table mergetags | fullscreen removeformat  | addcomment showcomments |  | checklist numlist bullist indent outdent |
                    emoticons charmap | block paragraph`,
                    content_style: 'body { font-family:Arial,sans-serif; font-size:12pt; direction: ltr !important; text-align: left; }',
                    directionality: 'ltr', // Set the editor's default text direction to LTR
                    branding: false,
                    language: 'tr',
                }}
                value={value}
                onEditorChange={handleEditorChange}
            />
        </>
    );
};

export default BlogEditor;
